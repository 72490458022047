import axios from '@/axios/globalaxios'

export const getTableList = data => {
    return axios({
        url: "/tradelab/marketPortfolioManagement/findPortfolioManagementByAcidAndType",
        method: 'post',
        data: data
    })
}
export const findFundTrendByAcid = data => {
    return axios({
        url: "/tradelab/marketPortfolioManagement/findFundTrendByAcid",
        method: 'post',
        data: data
    })
}
export const findIndexByAcidAndFid = data => {
    return axios({
        url: "/tradelab/marketPortfolioManagement/findIndexByAcidAndFid",
        method: 'post',
        data: data
    })
}

//外汇账户资金
export const findUserAccountAmount = data => {
    return axios({
        url: "/tradelab/forexOrder/findUserAccountAmount",
        method: 'post',
        data: data
    })
}
// 外汇交易订单
export const forexOrders = data => {
    return axios({
        url: "/tradelab/forexOrder/forexOrders",
        method: 'post',
        data: data
    })
}
//外汇货币类型
export const findCurrencyType = data => {
    return axios({
        url: "/tradelab/forexOrder/findCurrencyType",
        method: 'get',
    })
}
// 查询外汇指标
export const findIndexByAcid = data => {
    return axios({
        url: "/tradelab/forexOrder/findIndexByAcid",
        method: 'post',
        data: data
    })
}

// 获取资产走势
export const findAssetTrendByAcid = data =>{
    return axios({
        url: "/tradelab/forexOrder/findAssetTrendByAcid",
        method: 'post',
        data: data
    })
}
