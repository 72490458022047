import { findGroupByUserIdAndClassId } from "../../classApi";
import dayjs from "dayjs";
import { listData } from "../../group-list/listData";
// 获取 学生下所有课程
export const checkCards = (item, router, data) => {
  sessionStorage.setItem("classId", item.id);
  sessionStorage.setItem("courseType", item.courseType);
  sessionStorage.setItem("courseName", item.courseName);
  
  let param = {
    userId: JSON.parse(sessionStorage.getItem("user")).userId,
    classId: item.id,
    pageSize: data.pageSize,
    page: data.currentPage,
  };
  findGroupByUserIdAndClassId(param).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      if (newRes.whetherToJoinTheGroup !== 0) {
        sessionStorage.setItem("classInfo", JSON.stringify(newRes.userGroup));
        sessionStorage.setItem("acid", newRes.userGroup.acid);
        sessionStorage.setItem("groupId", newRes.userGroup.groupId);
        sessionStorage.setItem("courseUserId", newRes.userGroup.courseUserId);
        router.push({ path: "/course/home" });
      } else {
        router.push({ path: "/megagame/groupList", query: { classId: item.id } });
      }
    }
  });
};
