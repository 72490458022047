import { createStore } from 'vuex'
 
export default createStore({
  state: {
    menu_lists: [],  //菜单,
    roles:[],
    
  },
  getters: {
    account(state) {
      return state.menu_lists  // 读取菜单列表
    }
  },
  mutations: {
    // 增加菜单
    setroles(state,roles) {
      state.roles = roles
    }
  },
  actions: {
    menu_add({ commit }, data) {
      commit('menuAdd', data)
    },
    // 登出时调用将菜单数据删除
    menu_delect({ commit }) {
      commit('menuDelect')
    }
  },
  modules: {
  }
})