<template>
  <el-dialog :title="setType === 0 ? '编辑课程' : '新增课程'" :model-value="visbleBoolean" :close-on-click-modal="false"
    :close-on-press-escape="false" :before-close="handleClose" width="900px">
    <el-form ref="formlinedom" :model="formline" id="gradeform" size="small" :rules="rules">
      <div class="felxbox">
        <el-form-item label="课程名称" prop="classname">
          <el-input v-model="formline.classname" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="gptype">
          <!-- <el-select v-model="formline.gptype" style="width: 100%" :disabled="setType===0">
            <el-option :label="item.label" :value="item.value" v-for="(item, index) in typeList" :key="index"></el-option>
          </el-select> -->
          <el-checkbox-group  v-model="formline.gptype" style="width: 100%" :disabled="setType===0" @change="gptypeChange">
            <el-checkbox :label="item.value" v-for="(item, index) in typeList"  :key="item.value" :disabled="item.disabled">{{ item.label }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="上课时间" prop="schooltime">
          <!-- :disabledDate="disablesDate" -->
          <el-date-picker :disabled="setType === 0" v-model="formline.schooltime" type="daterange" @change="dataChaneg" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" style="width: 100%" format="YYYY-MM-DD">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="每组资金" class="unit" prop="groupmoeny">
          <el-input type="number" :controls="false" style="width: 100%" v-model="formline.groupmoeny"
            :disabled="setType === 0" @change="changeInput" placeholder="请输入内容"></el-input><span class="unitName">元</span>
        </el-form-item>
      </div>
      <div class="groupbox">
        <el-form-item label="每组资金" class="group_items">
          <div v-if="formline.gptype.length > 0"  style="margin-top:23px">
            <div class="box"  v-for="(item, index) in formline.gptype" :key="item + index">
              <el-form-item v-if="item != 'wh'&&item != 'qh'" class="info_item" :prop='`dynamicList.${item}0`'
                :rules="{ required: true, message: '输入项不能为空', trigger: 'blur' }">
                {{item == 'gp'?'股票':item == 'zq'?'债券':item == 'qh'?'期货':'外汇'}}仓位不得低于总资产的
                <el-input @input="inputCheck()" v-model="formline.dynamicList[`${item}0`]"
                  style="width: 60px" />
                %，
              </el-form-item>
              <el-form-item v-if="item != 'wh'&&item != 'qh'" class="info_item" :prop='`dynamicList.${item}1`'
                :rules="{ required: true, message: '输入项不能为空', trigger: 'blur' }">
                不得高于总资产的
                <el-input @input="inputCheck()" v-model="formline.dynamicList[`${item}1`]"
                  style="width: 60px" />
                %，
              </el-form-item>
              <el-form-item v-if="item != 'wh'&&item != 'qh'" class="info_item" :prop='`dynamicList.${item}2`'
                :rules="{ required: true, message: '输入项不能为空', trigger: 'blur' }">
                单只{{item == 'gp'?'股票':item == 'zq'?'债券':item == 'qh'?'期货':'外汇'}}仓位不得高于总资产的
                <el-input @input="inputCheck()" v-model="formline.dynamicList[`${item}2`]"
                  style="width: 60px" />
                %
              </el-form-item>
            </div>
          </div>
          <!-- <template v-for="(item, index) in group" :key="index"> -->
            <!-- <div :class="index > 0 && formline.gptype === 'zh' ? 'box paddingleft' : 'box'"
              v-if="formline.gptype === item.field || formline.gptype === 'zh'">
              <el-form-item class="info_item" :prop='`dynamicList.${item.field}0`'
                :rules="{ required: true, message: '输入项不能为空', trigger: 'blur' }">
                {{ item.key }}仓位不得低于总资产的
                <el-input @input="inputCheck($event, item, '0')" v-model="formline.dynamicList[`${item.field}0`]"
                  style="width: 60px" />
                %，
              </el-form-item>
              <el-form-item class="info_item" :prop='`dynamicList.${item.field}1`'
                :rules="{ required: true, message: '输入项不能为空', trigger: 'blur' }">
                不得高于总资产的
                <el-input @input="inputCheck($event, item, '1')" v-model="formline.dynamicList[`${item.field}1`]"
                  style="width: 60px" />
                %，
              </el-form-item>
              <el-form-item class="info_item" :prop='`dynamicList.${item.field}2`'
                :rules="{ required: true, message: '输入项不能为空', trigger: 'blur' }">
                单只{{ item.key }}仓位不得高于总资产的
                <el-input @input="inputCheck($event, item, '2')" v-model="formline.dynamicList[`${item.field}2`]"
                  style="width: 60px" />
                %
              </el-form-item>
            </div>
            <div v-if="formline.groupmoeny && formline.gptype == 'zh'" style="text-align: right;font-weight: 500;">

              <span v-if="item.key == '股票' || item.key == '债券'">
                股票债券账户初始资金为{{ formline.groupmoeny }}
              </span>
              <span v-else>
                {{ item.key }}账户初始资金为{{ formline.groupmoeny }}
              </span>
            </div>
            <div v-if="formline.groupmoeny && formline.gptype === item.field && formline.gptype != 'zh'"
              style="text-align: right;font-weight: 500;">{{ item.key }}账户初始资金为{{ formline.groupmoeny }}</div> -->
          <!-- </template> -->
        </el-form-item>
      </div>
      <el-form-item label="拉取课程" class="unit" v-if="setType !== 0">
        <el-button type="primary" size="mini" @click="SynStudents">关联课程</el-button>
        <div v-if="schoolCorrespondingCourses !== ''">
          <br />
          <span>课程编号：</span>{{ schoolCorrespondingCourses }}
        </div>
        <div v-if="courseClassName !== ''">
          <span>课程名称：</span>{{ courseClassName }}
        </div>
      </el-form-item>
      <div v-if="setType !== 0 && innerVisible === true">
        <el-table row-key="date" ref="filterTable" :data="spreadTable" height="400" style="width: 100%">
          <el-table-column prop="courseNo" label="课程编号" sortable></el-table-column>
          <el-table-column prop="courseName" label="班级名称" :filters="className"
            :filter-method="filterClass"></el-table-column>
          <el-table-column prop="courseClassName" label="课程名称" :filters="courseName"
            :filter-method="filterCourse"></el-table-column>
          <el-table-column prop="courseType" label="课程类型" :filters="courseType"
            :filter-method="filterCourseType"></el-table-column>
          <el-table-column label="操作" width="100px">
            <template #default="scope">
              <el-button size="mini" type="primary" @click="synchro(scope.row)">关联</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="felxbox">
        <el-form-item>
          <el-button type="primary" @click="submitClass">完成</el-button>
          <el-button @click="handleClose">取消</el-button>
        </el-form-item>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { reactive, ref, watch, toRefs } from "vue";
import { addCourse, updCourse } from "../../views/teacher-manage/class-manage/classApi";
import dayjs from "dayjs";
import SpreadTable from '@/components/SpreadTable'
import { ElMessage } from "element-plus";
import { viewSchoolCourses } from "./gradeApi";
const groupList = [
  {
    key: "股票",
    field: "gp",
  },
  {
    key: "债券",
    field: "zq",
  },
];
export default {
  name: "gradeform",
  props: {
    visbleBoolean: { type: Boolean },
    modifyData: { type: [Number, Object] },
    setType: { type: Number, default: 0 }
  },
  components: {
    SpreadTable,
  },
  emits: ['modify', 'update:visbleBoolean', 'closeDio'],
  setup(props, { emit }) {

    const disablesDate = (time) => {
      if (time.getTime() < new Date() - 8.64e7) {
        return true;
      }
    };

    const typeList = reactive([
      {
        label: "股票",
        value: "gp",
        disabled:false,
      },
      {
        label: "债券",
        value: "zq",
        disabled:false,
      },
      // {
      //   label: "期货",
      //   value: "qh",
      //   disabled:false,
      // },
      {
        label: "外汇",
        value: "wh",
        disabled:false,
      },

      // {
      //   label: "期货",
      //   value: "qh",
      //   disabled:false,
      // },
      // {
      //   label: "外汇",
      //   value: "wh",
      //   disabled:false,
      // },
      // {
      //   label: "综合",
      //   value: "zh",
      //   disabled:false,
      // },
    ]);
    const formline = reactive({
      classname: "",
      gptype: [],
      schoolCorrespondingCourses: "",
      courseClassName: "",
      schooltime: [],
      groupmoeny: 100,
      num: 1,
      dynamicList: {
        gp0: '',
        gp1: '',
        gp2: '',
        zq0: '',
        zq1: '',
        zq2: '',
        qh0: '',
        qh1: '',
        qh2: '',
        wh0: '',
        wh1: '',
        wh2: '',
      }
    });
    const group = reactive(groupList);
    const data = reactive({
      codeType:99,
      innerVisible: false,
      startTime: undefined,
      endTime: undefined,
      loading: true,
      spreadTable: [],
      className: [],
      courseName: [],
      courseType: [],
      schoolCorrespondingCourses: "",
      courseClassName: "",
      operation: ['同步'],
    });
    const formlinedom = ref();
    const rules = reactive({
      classname: [
        { required: true, message: "请输入班级名称", trigger: "blur" },
      ],
      gptype: [
        // { required: true, message: "请选择类型", trigger: "change" }
      ],
      schooltime: [
        { required: true, message: "请选择上课时间", trigger: "change" },
      ],
      groupmoeny: [
        { required: true, message: "请输入每组资金", trigger: "blur" },
      ],
    });

    watch(() => props.modifyData, (newValue) => {
      formline.classname = newValue.courseName
      formline.gptype = newValue.courseType == 1 ? ['gp'] : newValue.courseType == 2 ? ['zq'] : newValue.courseType == 3 ? ['qh'] : newValue.courseType == 6 ?['wh'] : newValue.courseType == 5 ?['gp','zq'] : newValue.courseType == 7 ?['wh','gp'] :newValue.courseType == 8 ?['wh','zq'] :newValue.courseType == 9 ?['wh','qh'] :newValue.courseType == 4 ?['gp','zq','qh'] :newValue.courseType == 10 ?['wh','gp','zq'] :newValue.courseType == 11 ?['wh','gp','qh'] :newValue.courseType == 12 ?['wh','qh','zq'] : newValue.courseType == 13 ?['wh','gp','zq','qh'] : []
      formline.groupmoeny = newValue.initialFunding
      data.schoolCorrespondingCourses = newValue.schoolCorrespondingCourses
      data.courseClassName = newValue.courseClassName
      formline.schooltime = [newValue.createTime, newValue.endTime]
      formline.dynamicList.gp0 = newValue.allStockMin * 100
      formline.dynamicList.gp1 = newValue.allStockMax * 100
      formline.dynamicList.gp2 = newValue.singleStockMax * 100
      formline.dynamicList.zq0 = newValue.allBondMin * 100
      formline.dynamicList.zq1 = newValue.allBondMax * 100
      formline.dynamicList.zq2 = newValue.singleBondMax * 100
      formline.dynamicList.qh0 = newValue.allFuturesMin * 100
      formline.dynamicList.qh1 = newValue.allFuturesMax * 100
      formline.dynamicList.qh2 = newValue.singleFuturesMax * 100
    }, { deep: true }
    )


    const handleClose = () => {
      formlinedom.value.resetFields();
      data.schoolCorrespondingCourses = ''
      data.courseClassName = ''
      data.innerVisible = false
      refSet()
      emit("closeDio", false);
    };

    const gptypeChange = () => {
      // if (formline.gptype.indexOf("zh") != -1) {
      //   typeList[0].disabled = true
      //   typeList[1].disabled = true
      //   typeList[2].disabled = true
      // } else {
      //   typeList[0].disabled = false
      //   typeList[1].disabled = false
      //   typeList[2].disabled = false
      // }
      // if (formline.gptype.indexOf("gp") != -1 || formline.gptype.indexOf("zq") != -1 || formline.gptype.indexOf("qh") != -1) {
      //   typeList[4].disabled = true
      // } else {
      //   typeList[4].disabled = false
      // }
      console.log("formline.gptype",formline.gptype);

      if (formline.gptype.length == 1) {
        if (formline.gptype.indexOf("gp") != -1) {
          data.codeType = 1
        } else if (formline.gptype.indexOf("zq") != -1) {
          data.codeType = 2
        } else if (formline.gptype.indexOf("qh") != -1) {
          data.codeType = 3
        } else if (formline.gptype.indexOf("wh") != -1) {
          data.codeType = 6
        } 
      } else if (formline.gptype.length == 2) {
        if (formline.gptype.indexOf("gp") != -1 && formline.gptype.indexOf("zq") != -1) {
          data.codeType = 5
        } else if (formline.gptype.indexOf("gp") != -1 && formline.gptype.indexOf("wh") != -1) {
          data.codeType = 7
        } else if (formline.gptype.indexOf("wh") != -1 && formline.gptype.indexOf("zq") != -1) {
          data.codeType = 8
        } else if (formline.gptype.indexOf("wh") != -1 && formline.gptype.indexOf("qh") != -1) {
          data.codeType = 9
        } else if (formline.gptype.indexOf("gp") != -1 && formline.gptype.indexOf("qh") != -1) {
          data.codeType = 991
        } else if (formline.gptype.indexOf("zq") != -1 && formline.gptype.indexOf("qh") != -1) {
          data.codeType = 992
        } else {
          data.codeType = 99
        }
      } else if (formline.gptype.length == 3) {
        if (formline.gptype.indexOf("gp") != -1 && formline.gptype.indexOf("zq") != -1 && formline.gptype.indexOf("qh") != -1) {
          data.codeType = 4
        } else if (formline.gptype.indexOf("wh") != -1 && formline.gptype.indexOf("gp") != -1 && formline.gptype.indexOf("zq") != -1) {
          data.codeType = 10
        } else if (formline.gptype.indexOf("wh") != -1 && formline.gptype.indexOf("gp") != -1 && formline.gptype.indexOf("qh") != -1) {
          data.codeType = 11
        } else if (formline.gptype.indexOf("wh") != -1 && formline.gptype.indexOf("zq") != -1 && formline.gptype.indexOf("qh") != -1) {
          data.codeType = 12
        } else {
          data.codeType = 99
        }
      } else if (formline.gptype.length == 4) {
        if (formline.gptype.indexOf("gp") != -1 && formline.gptype.indexOf("zq") != -1 && formline.gptype.indexOf("qh") != -1 && formline.gptype.indexOf("wh") != -1) {
          data.codeType = 13
        }
      } else if (formline.gptype.length == 0) {
        data.codeType = 0
      }
    };
    

    const submitClass = () => {
	  gptypeChange();
      if (formline.gptype.length<1) {
        ElMessage.error("类型不能为空")
        return
      }
      if (data.codeType == 99) {
        ElMessage.error("此类型组合暂不支持")
        formline.gptype = []
        return
      }
      if (data.codeType == 991) {
        ElMessage.error("(股票&期货)组合暂不支持")
        formline.gptype = []
        return
      }
      if (data.codeType == 992) {
        ElMessage.error("(债券&期货)组合暂不支持")
        formline.gptype = []
        return
      }
      formlinedom.value.validate((valid) => {
        if (valid) {
          if (Number(formline.dynamicList.gp0) > Number(formline.dynamicList.gp1)) {
            ElMessage.error("股票最低仓位不得大于股票最高仓位")
            return;
          }
          if (Number(formline.dynamicList.gp2) > Number(formline.dynamicList.gp1)) {
            ElMessage.error("单只股票仓位不得大于股票最高仓位")
            return;
          }

          if (Number(formline.dynamicList.zq0) > Number(formline.dynamicList.zq1)) {
            ElMessage.error("证券最低仓位不得大于证券最高仓位")
            return;
          }
          if (Number(formline.dynamicList.zq2) > Number(formline.dynamicList.zq1)) {
            ElMessage.error("单只证券仓位不得大于证券最高仓位")
            return;
          }

          if (Number(formline.dynamicList.qh0) > Number(formline.dynamicList.qh1)) {
            ElMessage.error("期货最低仓位不得大于期货最高仓位")
            return;
          }
          if (Number(formline.dynamicList.qh2) > Number(formline.dynamicList.qh1)) {
            ElMessage.error("单只期货仓位不得大于期货最高仓位")
            return;
          }
          if (props.setType === 1) {
            // let codeType = formline.gptype === 'gp' ? 1 : formline.gptype === 'zq' ? 2 : formline.gptype === 'qh' ? 3 : 5
            let parame = {
              username: JSON.parse(sessionStorage.getItem('user')).username,
              course: {
                teacherId: JSON.parse(sessionStorage.getItem('user')).userId,
                courseName: formline.classname,
                courseType: data.codeType,
                classTime: data.startTime,
                endTime: data.endTime,
                schoolCorrespondingCourses: data.schoolCorrespondingCourses,
                initialFunding: parseFloat(formline.groupmoeny),
                allStockMin: delNum(formline.dynamicList.gp0),
                allStockMax: delNum(formline.dynamicList.gp1),
                singleStockMax: delNum(formline.dynamicList.gp2),
                allBondMin: delNum(formline.dynamicList.zq0),
                allBondMax: delNum(formline.dynamicList.zq1),
                singleBondMax: delNum(formline.dynamicList.zq2),
                allFuturesMin: delNum(formline.dynamicList.qh0),
                allFuturesMax: delNum(formline.dynamicList.qh1),
                singleFuturesMax: delNum(formline.dynamicList.qh2),
              }
            }
            addCourse(parame).then(res => {
              if (res.data.code === '200') {
                ElMessage.success(res.data.msg)
              } else {
                ElMessage.error(res.data.msg)
              }
              refSet()
              emit('modify', formline)
            })
          } else {
            // let codeType = formline.gptype === 'gp' ? 1 : formline.gptype === 'zq' ? 2 : formline.gptype === 'qh' ? 3 : 5
            let parame = {
              course: {
                id: props.modifyData.id,
                teacherId: JSON.parse(sessionStorage.getItem('user')).userId,
                courseName: formline.classname,
                courseType: data.codeType,
                classTime: data.startTime,
                endTime: data.endTime,
                initialFunding: parseFloat(formline.groupmoeny),
                allStockMin: delNum(formline.dynamicList.gp0),
                allStockMax: delNum(formline.dynamicList.gp1),
                singleStockMax: delNum(formline.dynamicList.gp2),
                allBondMin: delNum(formline.dynamicList.zq0),
                allBondMax: delNum(formline.dynamicList.zq1),
                singleBondMax: delNum(formline.dynamicList.zq2),
                allFuturesMin: delNum(formline.dynamicList.qh0),
                allFuturesMax: delNum(formline.dynamicList.qh1),
                singleFuturesMax: delNum(formline.dynamicList.qh2),
              }
            }
            updCourse(parame).then(res => {
              if (res.data.code === '200') {
                ElMessage.success(res.data.msg)
              } else {
                ElMessage.error(res.data.msg)
              }
              refSet()
              emit('modify', formline)
            })
          }
        }
      });
    };

    let refSet = () => {
      formline.classname = ''
      formline.gptype = []
      formline.schooltime = []
      formline.groupmoeny = 100
      formline.num = 1
      formline.dynamicList.gp0 = ''
      formline.dynamicList.gp1 = ''
      formline.dynamicList.gp2 = ''
      formline.dynamicList.zq0 = ''
      formline.dynamicList.zq1 = ''
      formline.dynamicList.zq2 = ''
      formline.dynamicList.qh0 = ''
      formline.dynamicList.qh1 = ''
      formline.dynamicList.qh2 = ''
    }
    let delNum = (val) => {
      let num = (parseFloat(val) / 100).toFixed(2)
      num = num === "NaN" ? 0 : num
      if (num > 1) { return ElMessage.warning('比率范围不能超过100%') }
      return parseFloat(num)
    }

    let dataChaneg = (val) => {
      if (val === null) {
        data.startTime = undefined
        data.endTime = undefined
      } else {
        data.startTime = dayjs(val[0]).format('YYYY-MM-DD')
        data.endTime = dayjs(val[1]).format('YYYY-MM-DD')
      }
    }

    let SynStudents = () => {
      data.loading = true
      viewSchoolCourses({
        "teacher": JSON.parse(sessionStorage.getItem('user')).username
      }).then(res => {
        if (res.data.code === '200') {
          data.spreadTable = res.data.data.viewSchoolCourseList
          let className = []
          let courseName = []
          let courseType = []
          for (let i in data.spreadTable) {
            className.push(data.spreadTable[i].courseName)
            courseName.push(data.spreadTable[i].courseClassName)
            courseType.push(data.spreadTable[i].courseType)
          }
          className = [...new Set(className)]
          courseName = [...new Set(courseName)]
          courseType = [...new Set(courseType)]
          data.className = delArrList(className)
          data.courseName = delArrList(courseName)
          data.courseType = delArrList(courseType)

          data.total = res.data.data.viewSchoolCourseList.length
          data.innerVisible = true
          setTimeout(() => {
            data.loading = false
          }, 300)
        } else {
          ElMessage.error(res.data.msg);
        }
      })
    }

    let delArrList = (arr) => {
      let arrs = []
      for (let i in arr) {
        arrs.push({ text: arr[i], value: arr[i] })
      }
      return arrs
    }
    let filterClass = (value, row) => { return row.courseName === value }
    let filterCourse = (value, row) => { return row.courseClassName === value }
    let filterCourseType = (value, row) => { return row.courseType === value }
    // 同步
    let synchro = (row) => {
      data.schoolCorrespondingCourses = row.courseNo
      data.courseClassName = row.courseClassName
      data.innerVisible = false
    }

    let changeInput = (val) => {
      if (parseFloat(val) <= 0) {
        formline.groupmoeny = 0
      } else if (parseFloat(val) < 10000000) {
        formline.groupmoeny = parseFloat(val)
      } else {
        formline.groupmoeny = 10000000
      }
    }
    let inputCheck = (e, item, index) => {

      console.log("formline.dynamicList",formline.dynamicList);
      // let value = e.replace(/[^\d]/g, '')
      // if (value) {
      //   value = Number(value.replace(/^0+(d)/, "$1"))
      //   formline.dynamicList[`${item.field}${index}`] = value > 100 ? 100 : value;
      // }
    }
    return {
      disablesDate,
      formlinedom,
      rules,
      formline,
      typeList,
      group,
      ...toRefs(data),
      dataChaneg,
      handleClose,
      submitClass,
      SynStudents,
      filterClass,
      filterCourse,
      filterCourseType,
      synchro,
      changeInput,
      inputCheck,
      gptypeChange
    };
  },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>

<style lang="less" scoped>
#gradeform {
  .felxbox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    .el-form-item {
      width: 48%;
      display: flex;
      flex-wrap: wrap;

      &:deep(.el-form-item__label) {
        width: 80px;
      }

      &:deep(.el-form-item__content) {
        flex: 1;
      }
    }

    .unit {
      position: relative;
      padding-right: 20px;
      box-sizing: border-box;

      .unitName {
        position: absolute;
        top: 50%;
        right: -20px;
        transform: translateY(-50%);
      }
    }
  }

  .groupbox {
    .el-form-item {
      flex-wrap: nowrap;

      .box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;

        &:deep(.el-form-item__content) {
          display: flex;
          flex-wrap: nowrap;
          flex: 1;
        }
      }

      .paddingleft {
        padding-left: 68px;
      }
    }
  }
}

.info_item {
  color: #636c82;
  font-size: 12px;
}
</style>
