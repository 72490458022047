<template>
  <div class="container">
    <div class="flex-box">
      <div class="item" v-for="(item, index) in menu" :key="index" @click="click(item, index)">
        <router-link :to="item.link" :class="menuIndex === index ? 'router-link-active router-link-exact-active' : ''">{{
          item.name }}</router-link>
      </div>

    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, computed, ref, inject } from "vue"
import { useRouter, onBeforeRouteUpdate } from "vue-router";
import { showTab } from "../NavSonMenu/navMethods";
// 学生端菜单
const menusList = [
  {
    name: '首页',
    link: '/index'
  },
  {
    name: '个人交易',
    link: '/course/trade-personal/stock'
  },
  {
    name: '课程管理',
    link: '/userClass'
  },
  {
    name: '大赛管理',
    link: '/megagame'
  },
  {
    name: '金融知识库',
    link: '/information'
  },
]
// 管理员端菜单
const adminMenusList = [
  {
    name: '首页',
    link: '/index'
  },
  {
    name: '用户管理',
    link: '/userManage'
  },
  {
    name: '角色管理',
    link: '/roleManage'
  },
  {
    name: '菜单管理',
    link: '/Menu'
  },
  {
    name: '学校管理',
    link: '/schoolManage'
  },
  {
    name: '清算管理',
    link: '/liquidation'
  },
  // {
  //     name:'权限管理',
  //     link:'/powerManage'
  // },
  {
    name: '公告管理',
    link: '/noticeManage'
  },
  {
    name: '金融知识库',
    link: '/information'
  },

]
// 教师端菜单
const menuTeacherArr = [
  {
    name: '首页',
    link: '/index'
  },
  {
    name: '个人交易',
    link: '/course/trade-personal/stock'
  },
  {
    name: '课程管理',
    link: '/classManage'
  },
  {
    name: '大赛管理',
    link: '/megagame'
  },
  {
    name: '金融知识库',
    link: '/information'
  },
  // {
  //     name:'资料管理',
  //     link:'/a'
  // },
]
export default {
  name: 'listnav',
  setup() {
    const reload = inject("reload");
    let router = useRouter();
    let menuIndex = ref(-1)
    let user = unescape(decodeURI(sessionStorage.getItem('logger_tv'))).slice(3);
    const menuArr = reactive(menusList)
    const menuArrAdmin = reactive(adminMenusList)
    const menu = reactive([]);
    onMounted(() => {
      let roleList = JSON.parse(sessionStorage.getItem("roleList"))
      roleList.sort((a, b) => {
        return a.sort - b.sort
      })
      roleList.forEach((item) => {
        if (item.url != '/personalSettings' && item.url != '/details') {
          let obj = {
            name: item.urlDescription,
            link: item.url === '/course' ? '/course/trade-personal/stock' : item.url,
          }
          menu.push(obj)
        }
      })
      // if(roleList)
      // menuIndex.value = JSON.parse(sessionStorage.getItem('indexKey'))
      if (user === 'null') { router.push('/login') }
      if (JSON.parse(sessionStorage.getItem('indexKey'))) {
        menuIndex.value = JSON.parse(sessionStorage.getItem('indexKey'))
      } else {
        menuIndex.value = 0
      }

    })
    onBeforeRouteUpdate((to) => {
      for (let i in menu.value) {
        if (to.path === menu.value[i].link) {
          sessionStorage.setItem('indexKey', i)
          menuIndex.value = JSON.parse(sessionStorage.getItem('indexKey'))
        }
      }
    })
    // const menu = computed(() => {
    //   // if (user === '超级管理员') {
    //   //   return menuArrAdmin
    //   // } else if (user === '学生') {
    //   //   return menuArr
    //   // } else {
    //   //   return menuTeacherArr
    //   // }
    // })

    let click = (item, index) => {
      sessionStorage.setItem('indexKey', index)
      menuIndex.value = index
      // if (item.name === '个人交易') {
      //   sessionStorage.setItem('acid', JSON.parse(sessionStorage.getItem('user')).acId)
      // }
      //当为教师端课程管理时，刷新页面用于更新数据
      if (item.link == "/classManage") {
        reload()
      }
    }

    return {
      menuArr,
      menuTeacherArr,
      menuIndex,
      menuArrAdmin,
      menu,
      click
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  height: 100%;

  .flex-box {
    // display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top:28px;

    .item {
      width: 120px;
      height: 50px;
      font-size: 14px;
      justify-content: center;
      line-height: 50px;
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 10px;
      // margin-right: 15px;

      a {
        width: 100%;
        display: flex;
        // color: #fff;
        font-size: 14px;
        font-size: 14px;
        height: 85%;
        align-items: center;
        padding: 0 15px;
        border-left: 6px solid #fff;;
        color: #666666;
        text-align: center;
        display:block;
        // padding: 0 35px;
        // white-space: nowrap;

        &:hover {
          background: #FBFBFC;
          color: #3B6EFB ;
        }
      }

      .router-link-exact-active {
        border-left: 6px solid #3B6EFB;
        text-align: center;
        display:block;
        width: 100%;
        background:  #FBFBFc;
        color: #3B6EFB ;
      }
    }
  }
}
</style>
