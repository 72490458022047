import axios from '@/axios/globalaxios'

export const findClassByTeacherIdOrNull = data =>{
    return axios({
        url: "/tradelab/course/findClassByTeacherIdOrNull",
        method: 'post',
        data:data
    })
}
// 添加课程
export const addCourse = data =>{
    return axios({
        url: "/tradelab/course/addCourse",
        method: 'post',
        data:data
    })
}
// 删除 修改 课程
export const updCourse = data =>{
    return axios({
        url: "/tradelab/course/updCourse",
        method: 'post',
        data:data
    })
}