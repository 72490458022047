import { findClassByTeacherIdOrNull, findClassByUserId } from "./classApi";
import dayjs from "dayjs";
// 获取 学生下所有课程
export const getClassLists = (data, type) => {
  data.loading = true;
  let param = {
    userId: JSON.parse(sessionStorage.getItem("user")).userId,
    courseStatus: type === 0 ? data.classStart : 1, //判断如果是从首页进入，我的课程则只查进行中的
    keyword: data.classInput,
    pageSize: 1000,
    page: 1,
  };
  findClassByTeacherIdOrNull(param).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.itemList = newRes.userCoursesPageInfo.list;
      dealDate(data.itemList, ["createTime", "endTime", "classTime"]);
      setTimeout(() => {
        data.loading = false;
      }, 300);
    }
  });
};

// 格式化 日期
export const dealDate = (data, dealArr) => {
  let arr = data;
  for (let i = 0; i < arr.length; i++) {
    let res = arr[i];
    for (let j in dealArr) {
      res[dealArr[j]] = dayjs(res[dealArr[j]]).format("YYYY-MM-DD");
    }
  }
  return arr;
};
