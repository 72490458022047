import axios from '@/axios/globalaxios'

//查找全部公告
export const announcements = data =>{
    return axios({
        url: "/tradelab/announcement/announcements",
        method: 'post',
        data:data
    })
}

//添加公告信息
export const addAnnouncement = data =>{
    return axios({
        url: "/tradelab/announcement/addAnnouncement",
        method: 'post',
        data:data
    })
}


//  修改公告信息
export const update = data =>{
    return axios({
        url: "/tradelab/announcement/update",
        method: 'post',
        data:data
    })
}


//  删除公告信息
export const deletedeL = data =>{
    return axios({
        url: "/tradelab/announcement/delete",
        method: 'post',
        data:data
    })
}

//  查找Id查找公告
export const announcementById = data =>{
    return axios({
        url: "/tradelab/announcement/announcementById",
        method: 'post',
        data:data
    })
}


//  查询学校
export const schoolList = data =>{
    return axios({
        url: "/tradelab/school/schools",
        method: 'post',
        data: data
    })
}



