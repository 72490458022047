<template>
  <el-config-provider :locale="locale">
    <!-- 动态title取值暂改为"name" -->
    <router-view v-wechat-title="$route.meta.title" v-if="isRouterAlive"/>
  </el-config-provider>
</template>
<script>
//引入vue方法
import { ElConfigProvider } from "element-plus";
import store from './store'
import { provide, ref, nextTick } from "vue";
//中文包
import zhCn from "element-plus/lib/locale/lang/zh-cn";
//引入路由
// import router from "router"
export default {
  name: 'App',
  components: {
    ElConfigProvider
  },
  setup() {
    store.commit('setroles', [])
    //添加刷新页面方法
    const isRouterAlive  = ref(true);
    const reload = () => {
      isRouterAlive.value = false;
      nextTick(() => {
        isRouterAlive.value = true
      })
    }
    provide("reload", reload);
 
    return {
      isRouterAlive,
      locale: zhCn
    };

  },

}

</script>

<style>
.cell {
  text-align: center
}
</style>