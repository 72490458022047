<template>
  <div class="footer">
<!--        {{msg}}-->
  </div>
</template>

<script>
import {ref} from "vue"
export default {
  name:'companytip',
  setup() {
   const msg = ref('copyright © 2021 经数 （上海）信息科技有限公司')
    return {
       msg
    }
  }
}
</script>

<style lang="less" scoped>
.footer{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999999;
    // position: absolute;
    // bottom: 20px;
    // left: 50%;
    // transform: translateX(-50%);
}
</style>
