import {
  accountHistoryStockDividendInformation,
  cancelOrder,
  findCashFlow,
  findDelegation,
  findPortfolioManagementDetailedByAcidAndType,
  findQuotationByCondition,
  findSecurityDeposit,
  orderTransactionInformation,
} from "./tradeApi";
import { getGroupTables } from "../group-manage/groupMethods";
import { getStockListPlans } from "../stock-manage/stockMethods";
import {
  findDetailedStockCode,
  findTargetamountByAcid,
} from "../stock-manage/stockApi";
import { cloneDeep } from "lodash";
import { ElMessage } from "element-plus";
import dayjs from "dayjs";
import { getTableList } from "../group-manage/groupApi";

//切换菜单 事件
export const menuChanges = (item, data, dom) => {
  if (item.type === "tradeMenu") {
    data.showOther = 1;
    dom.optionTab(1, -1);
    data.sonMenuType = item.key;
    // optionTab
  }
  if (item.type === "sonMenu") {
    data.showOther = item.key;
  }
  data.pageSize = 10;
  data.currentPage = 1;
  data.startTime = undefined;
  data.endTime = undefined;
  data.datePicker = "";
  data.spreadTable = [];
  allMedthods(data);
};

//  点击 盘口 获取数据
export const clickGetPrice = (item, data) => {
  data.price = item;
};

//  获取 charts 图数据
export const getCharts = (data, code) => {
  if (data.megaMode && !code) {
    //大赛模式下 没传code 不掉接口
    data.megaChartList = [];
    return;
  }
  if (data.megaMode) {
    data.loadingCharts = true;
    data.showEmpty = true;
  }
  if (!data.code) return;
  let exchangeType = data.code.slice(0, 2) === "sh" ? "1" : "0";
  let parame = {
    startTime: Date.parse(new Date()) / 1000 - 365 * 3 * 24 * 3600,
    endTime: Date.parse(new Date()) / 1000,
    code: data.megaMode ? code : data.code.slice(2),
    exchangeType: exchangeType,
  };
  findQuotationByCondition(parame).then((res) => {
    let arr = [];
    if (res && res.data.code === "200") {
      let newRes = res.data.data;
      for (let i in newRes.marketInformations) {
        let dataMations = newRes.marketInformations[i];
        arr.push([
          dataMations.date,
          dataMations.fopen,
          dataMations.fclose,
          dataMations.flow,
          dataMations.fhigh,
        ]);
      }
      let resOption = splitData(arr);
      function splitData(rawData) {
        var categoryData = [];
        var values = [];
        for (var i = 0; i < rawData.length; i++) {
          categoryData.push(rawData[i].splice(0, 1)[0]);
          values.push(rawData[i]);
        }
        return {
          categoryData: categoryData,
          values: values,
        };
      }
      let xAxis = resOption.categoryData;
      let value = resOption.values;
      data.megaChartList = resOption.values;
      data.option = {
        // backgroundColor: '#12cf96',
        backgroundColor: "#fff",
        title: {
          text: data.megaMode ? data.chartTitle : data.codeName,
          left: 0,
        },
        tooltip: {
          show: true,
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
          formatter: function (param) {
            // return param.name + '<br>' + (param.data.coord || '')
            param = param[0];
            return [
              "日期" +
                "          " +
                param.name +
                '<hr size=1 style="margin: 3px 0">',
              "今开" + "          " + param.data[1] + "<br/>",
              "昨收" + "          " + param.data[2] + "<br/>",
              "最低" + "          " + param.data[3] + "<br/>",
              "最高" + "          " + param.data[4] + "<br/>",
            ].join("");
          },
        },
        legend: {
          data: ["日K"],
        },
        // legend: {
        //   data: ["日K", "MA5", "MA10", "MA20", "MA30"],
        // },
        grid: {
          left: "10%",
          right: "10%",
          bottom: "15%",
        },
        xAxis: {
          type: "category",
          data: xAxis,
          scale: true,
          boundaryGap: false,
          axisLine: { onZero: false },
          splitLine: { show: false },
          splitNumber: 20,
          min: "dataMin",
          max: "dataMax",
        },
        yAxis: {
          scale: true,
          splitArea: {
            show: true,
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 30,
            end: 70,
          },
          {
            show: true,
            type: "slider",
            y: "90%",
            start: 0,
            end: 75,
          },
        ],
        series: [
          {
            name: "日K",
            type: "k",
            data: value,
            itemStyle: {
              normal: {
                color: "#ec0000",
                color0: "#00da3c",
                borderColor: "#8A0000",
                borderColor0: "#008F28",
              },
            },
            markPoint: {
              label: {
                normal: {
                  formatter: function (param) {
                    return param != null ? Math.round(param.value) : "";
                  },
                },
              },
              data: [
                // {
                //   name: 'highest value',
                //   type: 'max',
                //   valueDim: 'highest'
                // },
                // {
                //   name: 'lowest value',
                //   type: 'min',
                //   valueDim: 'lowest'
                // },
                // {
                //   name: 'average value on close',
                //   type: 'average',
                //   valueDim: 'close'
                // }
              ],
              tooltip: {
                formatter: function (param) {
                  return param.name + "<br>" + (param.data.coord || "");
                },
              },
            },
            markLine: {
              symbol: ["none", "none"],
              data: [
                [
                  {
                    name: "from lowest to highest",
                    type: "min",
                    valueDim: "lowest",
                    symbol: "circle",
                    symbolSize: 10,
                    label: {
                      normal: { show: false },
                      emphasis: { show: false },
                    },
                  },
                  {
                    type: "max",
                    valueDim: "highest",
                    symbol: "circle",
                    symbolSize: 10,
                    label: {
                      normal: { show: false },
                      emphasis: { show: false },
                    },
                  },
                ],
              ],
            },
          },
        ],
      };
      data.option.title.text=data.option.title.text?data.option.title.text:data.codeList[0].name
      data.loadingCharts = false;
      data.showEmpty = false;
    }
  });
  // data.price = item
};

// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  allMedthods(data);
};

// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  allMedthods(data);
};

// 方法汇总调用
export const allMedthods = (data) => {
  data.spreadTable = [];
  if (data.showOther === 2) {
    getBackTables(data,"cancellations");
  }
  if (data.showOther === 3) {
    data.tagType = parseInt(data.routeId);
    getGroupTables(data);
  }
  if (data.showOther === 4) {
    getBackTablesList(data);
  }
  if (data.showOther === 5) {
    getTradeLists(data);
  }
  if (data.showOther === 6) {
    getCapitalLists(data);
  }
  if (data.showOther === 7) {
    getAccountMoney(data);
  }
  if (data.showOther === 8) {
    getDistribution(data);
  }
  if (data.showOther === 9) {
    getBond(data);
  }
};

// 切换 历史查找事件
export const DateChanges = (val, data) => {
  data.pageSize = 10;
  data.currentPage = 1;
  if (val === null) {
    data.startTime = undefined;
    data.endTime = undefined;
  } else {
    data.startTime = Date.parse(val[0]) / 1000;
    data.endTime = Date.parse(val[1]) / 1000;
  }
  if (data.showOther === 3) {
    data.tagType = parseInt(data.routeId);
    console.log('1');
    getGroupTables(data);
  }
  if (data.showOther === 4) {
    getBackTablesList(data);
  }
  if (data.showOther === 5) {
    getTradeLists(data);
  }
  if (data.showOther === 6) {
    getCapitalLists(data);
  }
  if (data.showOther === 8) {
    getDistribution(data);
  }
};

// 获取委托数据
export const getBackTables = (data,cancellations) => {
  data.loading = true;
  let parame = {
    acid: JSON.parse(sessionStorage.getItem("acid")),
    // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid
    type: data.routeId,
    exchange: data.exchange,
    pageSize: data.pageSize,
    page: data.currentPage,
  };
  if(cancellations){
    parame.cancellations=1
  }
  findDelegation(parame).then((res) => {
    data.spreadTable=[]
    if (res.data.code === "200") {
      data.loading = false;
      let newRes = res.data.data;
      if (newRes.delegationPageInfo.list && newRes.delegationPageInfo.list.length > 0) {
        data.spreadTable = newRes.delegationPageInfo.list;
        data.total = newRes.delegationPageInfo.total;
        dealTableParame(data.spreadTable);
        data.loading = false;
        setTimeout(() => {
        }, 300);
      }
      
    }
  });
};

// 获取资金流水 数据
export const getCapitalLists = (data) => {
  data.spreadTable = [];
  data.loading = true;
  let parame = {
    acid: JSON.parse(sessionStorage.getItem("acid")),
    // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
    exchange: data.routeId === "3" ? 10 : 0,
    startTime: data.startTime,
    endTime: data.endTime + 86399,
    pageSize: data.pageSize,
    page: data.currentPage,
  };
  findCashFlow(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      if (data.startTime) {
        data.spreadTable = newRes.ttradeCashflows.list;
        data.total = newRes.ttradeCashflows.total;
      } else {
        data.spreadTable = newRes.ttradeCashflowtodays.list;
        data.total = newRes.ttradeCashflowtodays.total;
      }
      dealDate(data.spreadTable, ["time"]);
      setTimeout(() => {
        data.loading = false;
      }, 300);
    }
  });
};

// 查询委托数据
export const getBackTablesList = (data) => {
  data.spreadTable = []
  data.loading = true;
  let parame = {
    acid: JSON.parse(sessionStorage.getItem("acid")),
    // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
    type: data.routeId,
    startTime: data.startTime,
    endTime: data.endTime + 86399,
    pageSize: data.pageSize,
    page: data.currentPage,
  };
  findDelegation(parame).then((res) => {
    if (res.data.code === "200") {
      data.loading = false;
      let newRes = res.data.data;
      if (newRes.delegationPageInfo.list && newRes.delegationPageInfo.list.length > 0) {
        data.spreadTable = newRes.delegationPageInfo.list;
        data.total = newRes.delegationPageInfo.total;
        dealDate(data.spreadTable, ["date"]);
        dealTableParame(data.spreadTable);
        setTimeout(() => {
          data.loading = false;
        }, 300);
      }
      
    }
  });
};

// 获取成交行情 数据
export const getTradeLists = (data) => {
  data.spreadTable = [];
  data.loading = true;
  let parame = {
    acid: JSON.parse(sessionStorage.getItem("acid")),
    // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
    type: data.routeId,
    exchange: data.exchange,
    startTime: data.startTime,
    endTime: data.endTime + 86399,
    pageSize: data.pageSize,
    page: data.currentPage,
  };
  orderTransactionInformation(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      if (data.startTime) {
        data.spreadTable = newRes.orderTransactions.list;
        data.total = newRes.orderTransactions.total;
      } else {
        data.spreadTable = newRes.orderTransactions.list;
        data.total = newRes.orderTransactions.total;
      }
      dealDate(data.spreadTable);
      dealTableParame(data.spreadTable);
      setTimeout(() => {
        data.loading = false;
      }, 300);
    }
  });
};

// 获取资金账户
export const getAccountMoney = (data) => {
  data.loading = true;
  let parame = {
    acid: JSON.parse(sessionStorage.getItem("acid")),
    // type:data.routeId,
    // exchange: data.exchange,
    // startTime: data.startTime,
    // endTime: data.endTime,
    type: data.codeType,
    pageSize: data.pageSize,
    page: data.currentPage,
  };
  findPortfolioManagementDetailedByAcidAndType(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.spreadTable = newRes.portfolioManagementDetailed
        ? [newRes.portfolioManagementDetailed]
        : [];
      data.total = 0;
      setTimeout(() => {
        data.loading = false;
      }, 300);
    }
  });
};

// 获取分红送股记录
export const getDistribution = (data) => {
  data.spreadTable = [];
  data.loading = true;
  let parame = {
    acid: JSON.parse(sessionStorage.getItem("acid")),
    // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
    // type:data.routeId,
    // exchange: data.exchange,
    startTime: data.startTime,
    endTime: data.endTime,
    pageSize: data.pageSize,
    page: data.currentPage,
  };
  accountHistoryStockDividendInformation(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      if (data.startTime) {
        if (newRes.ttradeFundaccounttodays) {
          data.spreadTable = newRes.ttradeFundaccounttodays.list;
          data.total = newRes.ttradeFundaccounttodays.total;
        }
      } else {
        data.spreadTable = newRes.ttradeDividenAllotmentHistoryPageInfo.list;
        data.total = newRes.ttradeDividenAllotmentHistoryPageInfo.total;
      }
      // dealDate(data.spreadTable)
      // dealTableParame(data.spreadTable)
      setTimeout(() => {
        data.loading = false;
      }, 300);
    } else {
      setTimeout(() => {
        data.loading = false;
      }, 300);
    }
  });
};

// 获取 保证金记录
export const getBond = (data) => {
  data.loading = true;
  let parame = {
    acid: JSON.parse(sessionStorage.getItem("acid")),
    // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
    // type:data.routeId,
    // exchange: data.exchange,
    // startTime: data.startTime,
    // endTime: data.endTime,
    pageSize: data.pageSize,
    page: data.currentPage,
  };
  findSecurityDeposit(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      if (data.startTime) {
        data.spreadTable = newRes.securityDepositPageInfo.list;
        data.total = newRes.securityDepositPageInfo.total;
      } else {
        data.spreadTable = newRes.securityDepositPageInfo.list;
        data.total = newRes.securityDepositPageInfo.total;
      }
      // dealDate(data.spreadTable)
      // dealTableParame(data.spreadTable)
      setTimeout(() => {
        data.loading = false;
      }, 300);
    }
  });
};

// 格式化 日期
export const dealDate = (data, dealArr) => {
  let arr = data;
  for (let i = 0; i < arr.length; i++) {
    let res = arr[i];
    for (let j in dealArr) {
      // res[dealArr[j]] = res[dealArr[j]].toString().slice(0,4) +'-'+ res[dealArr[j]].toString().slice(4,6)+'-'+ res[dealArr[j]].toString().slice(6)
      res[dealArr[j]] = dayjs(res[dealArr[j]]).format("YYYY-MM-DD HH:mm:ss");
    }
  }
  return arr;
};

// 获取目标 list
export const getPlanLists = (data) => {
  let parame = {
    acid: JSON.parse(sessionStorage.getItem("acid")),
    // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
    type: data.routeId,
    lastState: 1,
    pageSize: data.pageSizePlan,
    page: data.currentPagePlan,
  };

  findTargetamountByAcid(parame).then((res) => {
    if (res.data.code === "200") {
		data.totalPlan=res.data.data.tfundTargetamountPageInfo.total;
      let newRes = res.data.data;
      data.tableDetail.isTotal=newRes?.tfundTargetamountPageInfo?.list.length==data.pageSizePlan?false:true
      data.tableDetail.loadingMore=false;
      //newRes?.tfundTargetamountPageInfo?.list.forEach((item) => data.spreadTablePlan.push(item));
	  data.spreadTablePlan=newRes?.tfundTargetamountPageInfo?.list;
      data.totalPlan = newRes.tfundTargetamountPageInfo.total;
      data.codeList = cloneDeep(data.spreadTablePlan).map((item) => {
        return { code: item.internalsid, name: item.name, fid: item.fid };
      });
      if (data.codeList.length === 0) {
        data.loadingCharts = true;
        data.showEmpty = true;
        if (data.megaMode) {
          data.loadingCharts = false;
          data.showEmpty = false;
        }
        data.code = "";
      } else {
        data.code = dealCodeSearch(data.codeList[0].code, data);
        data.codeName = data.codeList[0].name;
        if (data.code === undefined) {
          data.loadingPlan = true;
          data.loadingCharts = true;
          data.showEmpty = true;
        } else {
          getCharts(data);
        }
        // data.code = 'sh'+data.codeList[0].code
      }
      setTimeout(() => {
        data.loadingPlan = false;
      }, 300);
    }
  });
};

// 获取下拉
export const getSelectLists = (data) => {
  let parame = {
    acid: JSON.parse(sessionStorage.getItem("acid")),
    // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
    type: data.routeId,
    lastState: 1,
    pageSize: data.pageSizePlan,
    page: data.currentPagePlan,
  };
  findDetailedStockCode(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.spreadTablePlan = newRes.tfundTargetamountPageInfo.list;
      // data.totalPlan = newRes.tfundTargetamountPageInfo.total

      data.codeList = cloneDeep(data.spreadTablePlan).map((item) => {
        return { code: item.internalsid, name: item.name, fid: item.fid };
      });
      if (data.codeList.length === 0) {
        data.loadingCharts = true;
        data.showEmpty = true;
        if (data.megaMode) {
          data.loadingCharts = false;
          data.showEmpty = false;
        }
        data.code = "";
      } else {
        data.code = dealCodeSearch(data.codeList[0].code, data);
        data.codeName = data.codeList[0].name;
        if (data.code === undefined) {
          data.loadingPlan = true;
          data.loadingCharts = true;
          data.showEmpty = true;
        } else {
        
          getCharts(data);
        }
        // data.code = 'sh'+data.codeList[0].code
      }
      setTimeout(() => {
        data.loadingPlan = false;
      }, 300);
    }
  });
};

// 切换条数
export const sizeChangePlans = (val, data) => {
  data.pageSizePlan = val;
  if (data.tradeRadio === 0) {
    // 计划
    getPlanLists(data);
  } else {
    // 持仓
    getPositionLists(data);
  }
};

// 切换页数
export const currentChangePlans = (val, data) => {
  data.currentPagePlan = val;
  if (data.tradeRadio === 0) {
    // 计划
    getPlanLists(data);
  } else {
    // 持仓
    getPositionLists(data);
  }
};

export const codeChanges = (val, data) => {
  // data.code = 'sh' + val
  for (let i in data.codeList) {
    if (data.codeList[i].code === val) {
      data.codeName = data.codeList[i].name;
    }
  }
  data.code = dealCodeSearch(val, data);
  getCharts(data);
};

export const clickButtons = (val, data) => {
  // 撤单
  clickBackOrder(val.item, data);
};

export const changeTradeRedios = (data) => {

  data.loadingPlan = true;
  data.tableDetail.isTotal=true;
  data.tableDetail.loadingMore=false;
  data.tableDetail.noMore=false;
  data.spreadTablePlan = [];
  if (data.tradeRadio === 0) {
    // 计划
    getPlanLists(data);
  } else {
    // 持仓
    getPositionLists(data);
  }
};

// 获取持仓 list
export const getPositionLists = (data) => {
  let parame = {
    acid: JSON.parse(sessionStorage.getItem("acid")),
    // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
    type: data.routeId,
    lastState: 1,
    pageSize: data.pageSizePlan,
    page: data.currentPagePlan,
  };
  getTableList(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = [];
      data.tableDetail.isTotal=res.data?.data?.portfolioManagements?.list?.length==data.pageSizePlan?false:true
     
      data.tableDetail.loadingMore=false;
      if(res.data?.data?.portfolioManagements?.list){
        res.data?.data?.portfolioManagements?.list.forEach((item) => newRes.push(item));
      }
          let arr = newRes;
          data.spreadTablePlan=[];
          for (let i in arr) {
            data.spreadTablePlan.push({
              internalsid: arr[i].marketSID,
              name: arr[i].name,
              targetamount: arr[i].availableAmount,
            });
          }
  
      data.totalPlan = res.data.data.portfolioManagements.total;
      data.codeList = cloneDeep(data.spreadTablePlan).map((item) => {
        return {
          code: item.internalsid,
          name: item.name,
          fid: item.targetamount,
        };
      });
      if (data.codeList.length === 0) {
        data.loadingCharts = true;
        data.showEmpty = true;
        if (data.megaMode) {
          data.loadingCharts = false;
          data.showEmpty = false;
        }
        data.code = "";
      } else {
        data.code = dealCodeSearch(data.codeList[0].code, data);
        data.codeName = data.codeList[0].name;
        if (data.code === undefined) {
          data.loadingPlan = true;
          data.loadingCharts = true;
          data.showEmpty = true;
        } else {
          getCharts(data);
        }
        // data.code = 'sh'+data.codeList[0].code
      }
      setTimeout(() => {
        data.loadingPlan = false;
      }, 300);
    }
  });
};

// 点击撤单
export const clickBackOrder = (val, data) => {
  data.loading = true;
  let parame = {
    accountId: JSON.parse(sessionStorage.getItem("acid")),
    omid: val.omid,
  };
  cancelOrder(parame).then((res) => {
    if (res.data.code === "200") {
      if (res.data.msg === "撤单成功") {
        ElMessage.success(res.data.msg);
      } else {
        ElMessage.error(res.data.msg);
      }
      setTimeout(() => {
        getBackTables(data,"cancellations");
        data.loading = false;
      },3000)
      
    } else {
      ElMessage.warning(res.data.msg);
      data.loading = false;
    }
  });
};

// 获取交易所
export const dealCodeSearch = (oldval, data) => {
  let val= oldval
  if(oldval.length>6){
    val= oldval.substring(2)
  }
  let codeName = data.routeId === "1" ? "shares" : data.routeId === "2" ? "bond" : "forward";
  let codeList = JSON.parse(localStorage.getItem(codeName));
  if (data.routeId !== "3") {
    for (let j in codeList) {
      if (val === codeList[j].fgsid) {
        let type = codeList[j].fjys === 0 ? "sz" : "sh";
        return type + val;
      }
    }
  } else {
    for (let j in codeList) {
      if (val === codeList[j].fgsid) {
        let type = codeList[j].fjys === 10 ? "10nf_" : "11nf_";
        return type + val;
      }
    }
  }
};

// 处理 默认 参数
export const dealTableParame = (table, arr) => {
  for (let i in table) {
    if (table[i].hasOwnProperty("exchange")) {
      if (table[i].exchange === 0) {
        table[i].exType = "深证";
      } else if (table[i].exchange === 1) {
        table[i].exType = "上证";
      } else if (table[i].exchange === 10) {
        table[i].exType = "中金";
      } else if (table[i].exchange === 11) {
        table[i].exType = "能源";
      } else if (table[i].exchange === 21) {
        table[i].exType = "上期";
      } else if (table[i].exchange === 22) {
        table[i].exType = "大商";
      } else if (table[i].exchange === 23) {
        table[i].exType = "郑商";
      } else if (table[i].exchange === 30) {
        table[i].exType = "外汇宝";
      } else if (table[i].exchange === 100) {
        table[i].exType = "上财";
      }
    }
    if (table[i].hasOwnProperty("stype")) {
      let num = parseInt(table[i].stype);
      switch (num) {
        case 0:
          table[i].exType = "股票";
          break;
        case 1:
          table[i].exType = "股票";
          break;
        case 6:
          table[i].exType = "债券";
          break;
        case 9:
          table[i].exType = "债券";
          break;
        case 201:
          table[i].exType = "期货";
          break;
        case 301:
          table[i].exType = "期货";
          break;
      }
    }
    if (table[i].direction && table[i].direction != null)
      table[i].direction = table[i].direction === "0" ? "买入" : "卖出";
    if (table[i].tposition)
      table[i].tposition = table[i].tposition === "0" ? "开仓" : "平仓";
  }
  return table;
};

// 时间限制
export const checkAuditTime = (beginTime, endTime) => {
  var nowDate = new Date();
  var beginDate = new Date(nowDate);
  var endDate = new Date(nowDate);

  var beginIndex = beginTime.lastIndexOf(":");
  var beginHour = beginTime.substring(0, beginIndex);
  var beginMinue = beginTime.substring(beginIndex + 1, beginTime.length);
  beginDate.setHours(beginHour, beginMinue, 0, 0);

  var endIndex = endTime.lastIndexOf(":");
  var endHour = endTime.substring(0, endIndex);
  var endMinue = endTime.substring(endIndex + 1, endTime.length);
  endDate.setHours(endHour, endMinue, 0, 0);
  if (
    nowDate.getTime() - beginDate.getTime() >= 0 &&
    nowDate.getTime() <= endDate.getTime()
  ) {
    return 1;
  } else {
    return 2;
  }
};
export const scorllChanges = (data,val) => {
  if(val==='noMore'){
    data.tableDetail.noMore=true;
  }else{

    data.tableDetail.loadingMore=true;
    data.currentPagePlan=data.currentPagePlan+1
    if(data.tradeRadio === 0){
      getPlanLists(data)
    } else {
      // 持仓
      getPositionLists(data);
    }
  
  }
}