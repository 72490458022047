<template>
  <div class="home-main">
    <div v-if="route.path!=='/index'">
      <router-view/>
    </div>
    <div class="home" v-else>
    <el-row :gutter="20">
      <div class="home-main">
        <usercard class="left" :userData="userData" @updateName="updateName" />
        <livebess class="right" />
      </div>
    </el-row>
    <el-row :gutter="20" v-if="typeClass!==2">
      <div class="home-main">
        <personal class="left"  />
        <notice  class="right" />
      </div>
    </el-row>
    <el-row :gutter="20">
        <!--        <course @setcourse="setcourse" />-->
        <template v-if="typeClass === 0">
          <class-my :type="1"></class-my>
        </template>
        <template v-if="typeClass === 1">
          <class-teacher :type="1" ></class-teacher>
        </template>
    </el-row>
    <gradeform :visbleBoolean="visbleBoolean" @modify="modify" :modifyData="modifyData" />
   
  </div>
  </div>
 
</template>

<script>
import { testApi } from "@/api/test";
import usercard from "@/components/UserCard";
import livebess from "@/components/LivenessCard";
import personal from "@/components/PersonalCard";
import notice from "@/components/NoticeCard"
import course from "@/components/CourseCard"
import gradeform from "@/components/Gradeform/index.vue"
import classMy from "./course-manage/class-manage/home.vue"
import classTeacher from './teacher-manage/class-manage/home.vue'
import { onMounted, reactive, ref, toRef } from "vue";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "Home",
  components: {
    usercard,
    livebess,
    personal,
    notice,
    course,
    gradeform,
    classMy,
    classTeacher
  },
  setup(props, { emit }) {
    let typeClass = ref(0)
    onMounted(() => {
      let name = JSON.parse(sessionStorage.getItem('user'))
      userData.username = name.username
      userData.profession = name.userRoleName
      userData.userid = name.userId
      userData.name = name.name
      typeClass.value = name.userRoleName === '学生' ? 0 : name.userRoleName === '教师' ? 1 : 2
    })
    const userData = reactive({
      username: "zhenxun",
      userid: "111",
      profession: "教师",
      img: "",
    });
    const route = useRoute();
    const visbleBoolean = ref(false)
    let modifyData = reactive({ user: 'zhenxun' })

    const updateName = (value) => {};

    const setcourse = (item) => {
      modifyData.user = item
      visbleBoolean.value = true;
    }

    const modify = (formdata) => {}

    return {
      userData,
      typeClass,
      visbleBoolean,
      modifyData,
      updateName,
      setcourse,
      modify,
      route
    };
  },
}
</script>
<style scoped lang="less">
.home {
  width: 100%;
  color: #000;
  height: calc(100vh - 125px);
  overflow: auto;
  .el-row {
    margin-bottom: 20px;
  }
}
.home-main{
  display: flex;
  width: 100%;
  div{
    width: 100%;
  }
  .left{
    margin-right: 20px;
    width: 360px;
   
  }
  .right{
    flex: 1;
  }

}
</style>
