<template>
  <el-card class="box-cards">
    <template #header>
      <div class="card-header">
        <span>我的课程</span>
      </div>
    </template>
    <div class="courseContainer">
      <el-row :gutter="20">
        <el-col  :xs="12" :sm="12" :md="8" :lg="8" :xl="6" v-for="(item,index) in list" :key="index">
          <course :item="item" @setcourse="setcourse" />
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import { reactive, toRefs } from "vue";
import course from "@/components/Course/index";
export default {
  name: "mycourse",
  components: {
    course,
  },
  setup(props,{emit}) {
    const list = reactive([1,2,3,4,5,6]);
    const setcourse = (value) => {
        emit('setcourse',value)
    }
    return {
      list,
      setcourse
    };
  },
};
</script>

<style lang="less" scoped>
.box-cards {
  width: 100%;
  &:deep(.el-card__header) {
    border-bottom-color: transparent;
  }
  &:deep(.el-card__body) {
    padding-top: 0;
  }
  .courseContainer {
      .el-col{
          margin-bottom: 20px;
      }
  }
}
</style>
