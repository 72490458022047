<template>
  <div class="user-card">
  <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span class="card-header-line"></span>
        <span>个人信息</span>
      </div>
    </template>
    <div class="user">
    <div class="userData">
      <!-- headSculpture -->
      <img src="../../assets/headSculpture.png" alt="">
      <!-- <el-avatar style="line-height: 80px"> </el-avatar> -->
<!--      <el-avatar size="large" :src="defaultimg"> </el-avatar>-->
<!--      <div class="profession">{{userData.profession}}</div>-->
    </div>
    <div class="userinfomation">
      <div class="item">
        <!-- <span class="userlable">名称：</span> -->
        <span v-if="!editName" class="username">{{userData.name}}</span>
        <span class="user-title"> {{userData.profession}}</span>
      </div>
      <div class="item">
        <span class="userlable">学号：</span>
        <span v-if="!editName" class="username">{{userData.username}}</span>
<!--        <el-input v-else v-model="modifyValue" placeholder="请输入用户名" size="small" @keyup.enter="confirm" />-->
<!--        <i class="el-icon-edit" @click="editUserName(true)"></i>-->
<!--        <i class="el-icon-error" v-if="editName" @click="editUserName(false)"></i>-->
      </div>
      <!-- <div class="item">
        <span class="userlable">用户角色：</span>
        <span  class="username">{{userData.profession}}</span>
        <i class="el-icon-edit hiddenicon" ></i>
      </div> -->
    </div>
  </div>
  
  </el-card>
  <div>
    <img src="../../assets/head-bg.png" class="head-bg" alt="">
  </div>
</div>
</template>

<script>
import {onMounted, ref, watch} from "vue";
// import avatarimg from "@/assets/img.jpeg"

export default {
  name: "usercard",
  props:{   
      userData:{type:Object}
  },
  setup(props,{emit}) {
    const modifyValue = ref('')
    watch(() => props.userData,(newValue) => {
        modifyValue.value = newValue.username
    },{immediate:true})


    // const defaultimg = ref(avatarimg);

    const editName = ref(false)

    const editUserName = (boolean) => {
        editName.value = boolean;
    }

    const confirm = () => {
        editUserName(false)
        emit('updateName',modifyValue)
    }

    return {
        editName,
        editUserName,
        modifyValue,
        confirm
    }
  },
};
</script>

<style lang="less" scoped>
.user-card{
  width: 100%;
  position: relative;
  .head-bg{
    position: absolute;
    bottom:0;
    left:0;
    width:360px;
  }
}
.box-card {
    height: 100%;
    height:290px;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px 0px rgba(107,133,228,0.15);
    border-radius: 6px;
    width: 360px;
    box-sizing: border-box;
    .user{
      display: flex;
      padding-left:10px;
      align-items: center;
    }
  .userData {
    position: relative;
    img{
      width: 80px;
    height: 80px;
    }
    width: 80px;
    height: 80px;
    margin-right:20px;
   
    .profession {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 12px;
      transform: translateX(100%);
    }
  }
  &:deep(.el-card__header) {
    border-bottom-color: transparent;
  }
  &:deep(.el-avatar) {
    width: 80px;
    height: 80px;
    display: block;
  }
  .userinfomation{
      // margin: 30px auto;
      // text-align: center;
      .item{
          display: flex;
          align-items: center;
          .el-icon-edit,.el-icon-error{
              cursor: pointer;
              margin-left: 20px;
          }
          .hiddenicon{
              visibility: hidden;
          }
          .userlable{
            height: 14px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #666666;
            line-height: 14px;

          }
          
      }
      .item:nth-child(1){
        margin-bottom: 12px;
        display: flex;
        .username{
          height: 32px;
          font-size: 18px;
          line-height: 32px;
          font-family: MicrosoftYaHei;
          color: #03032C;
        }
        .user-title{
          margin-left:8px;
          padding:0px 6px;
          height: 24px;
          box-sizing: border-box;
          background: #F9FAFF;
          line-height: 22px;
          border-radius: 14px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #3B6EFB;
          border: 1px solid #3B6EFB;
        }
       
      }
      .item:nth-child(2){
        .username{
          height: 14px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #666666;
          line-height: 14px;
        }
      }
  }
}
</style>
