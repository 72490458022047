<template>
    <div>
      <el-card>
        <div>
          <div class="name-div"><i class="card-line"></i>{{ courseName}}</div>
          <div v-if="alertParam.currentWeek" class="week-div">{{alertParam.currentWeek}}</div>
        </div>
        <div v-if="courseDuration" style="clear: both;margin-bottom: 20px;font-size: 14px;color: #686480;">
          <span class="course-time">课程时间：{{courseDuration[0]}} - {{courseDuration[1]}}</span>
        </div>
        <div style="clear: both;">
          <nav-son-menu :menuData="roleList" :routerType="routerType" :direction="direction"></nav-son-menu>
        </div>
        
        <!-- <el-alert style="margin: 10px 0 "
            class="alertClass"
            type="warning"
            :closable="false" center show-icon
        >
          <template #title>
            <span style="display:inline-block;margin-right: 15px">课程名称：{{ courseName}}</span>
            <span>开始日期：{{alertParam.startTime}}</span>
            <span style="margin: 0 15px">结束日期：{{alertParam.endTime}}</span>
            <span>当前周次：{{alertParam.currentWeek}}</span>
          </template>
        </el-alert> -->
<!--      <div style="margin-bottom: 15px">-->
<!--        <el-breadcrumb separator-class="el-icon-arrow-right">-->
<!--          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>-->
<!--          <el-breadcrumb-item>{{$route.meta.title}}</el-breadcrumb-item>-->
<!--        </el-breadcrumb>-->
<!--      </div>-->
        <div style="margin: 20px;height: calc(100vh - 250px);min-height:500px;overflow: auto;clear: both;" class="margin-height">
          <router-view />
        </div>
        
      </el-card>
        
    </div>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs} from 'vue'
    import NavSonMenu from '@/components/NavSonMenu'
import {findTimeRoundByCourseId} from "./indexApi";
    export default defineComponent({
        name: "index",
        components:{
            NavSonMenu,
        },
        setup(){
            let data = reactive({
              courseDuration:[],
              // menuList :[
              //   {
              //     name: '小组排名',
              //     router: '/groupManage/groupRank',
              //     key: 1,
              //   },
              //   {
              //     name: '优秀研报',
              //     router: '/groupManage/findReport',
              //     key:2,
              //   },
              //   {
              //     name: '班级分组',
              //     router: '/groupManage/classGroup',
              //     key:3,
              //   },
              //   {
              //       name: '指标展示',
              //       router: '/groupManage/megagame',
              //       key:4,
              //       children:[
              //         {name: '关键比率',router: '/groupManage/megagame/key-ratio',key:11,},
              //         {name: '资产配置',router: '/groupManage/megagame/asset-allocation',key:12,},
              //         {name: '风险分析',router: '/groupManage/megagame/risk-analysis',key:13,},
              //         {name: '总仓证券',router: '/groupManage/megagame/security',key:14,},
              //         {name: '行业配置',router: '/groupManage/megagame/industry-allocation',key:15,},
              //       ]
              //   },
              //   {
              //     name: '学生管理',
              //     router: '/groupManage/studentManage',
              //     key:5,
              //   },
              //   // {
              //   //   name: '清算计算',
              //   //   router: '/groupManage/liquidation/class',
              //   //   key:6,
              //   // },
              //   {
              //     name: '时间轮次',
              //     router: '/groupManage/timeTurn',
              //     key:7,
              //   },
              //   {
              //     name: '作业评分',
              //     router: '/groupManage/homework',
              //     key:8,
              //   },

              // ],
              routerType: false,
              direction: true,
              roleList: [],
              alertParam:{},
              courseName:''
            })
          onMounted(() => {
            if(sessionStorage.getItem('course-duration')){
              data.courseDuration=sessionStorage.getItem('course-duration').split(',')
            }
          
            let role = [];
      let arr = JSON.parse(sessionStorage.getItem('roleList')).filter((item) => {
        if (item.url === '/classManage') {
          return item;
        }
      })
      // 去除盘后申报路径
      // let routeList = ['/course/trade-personal/stock', '/course/trade/declare', '/course/report/edit', '/course/report/draft', '/course/report/state', '/course/report/browser', '/course/user/account', '/course/user/jobChange']
      let routeList = ['/course/trade-personal/stock',  '/course/report/edit', '/course/report/draft', '/course/report/state', '/course/report/browser', '/course/user/account', '/course/user/jobChange']
      let newArr = [];
      // let roleList = JSON.parse(sessionStorage.getItem("roleList"))
      arr[0].childResources.sort((a, b) => {
	    return a.sort - b.sort
      })
      let index = 0;
      arr[0].childResources.forEach((element) => {
        if (!element.url.startsWith('/groupManage/megagame/')) {
          index = index + 1
          let obj = {
            name: element.urlDescription,
            router: element.url,
            key: index,
          }
          newArr.push(obj)
        }
        data.roleList = newArr;

      });
      newArr.push( {name: '指标展示',
                    router: '/groupManage/megagame',
                    key:newArr.length+1,})
      newArr.forEach((item, index) => {
        if (item.router === '/groupManage/megagame' ) {
          item.children=[]
          arr[0].childResources.forEach((element,elenIndex) => {
            if (element.url.startsWith('/groupManage/megagame/')) {
              item.children.push({ name: element.urlDescription, router: element.url, key: elenIndex, })
            }
          })
        }
        data.roleList =newArr
      })
      checkWeek()
        })
       
          let checkWeek = () => {
              let param = {
                courseId:sessionStorage.getItem('classId'),
              }
            findTimeRoundByCourseId(param).then(res=>{
              if (res.data.code === '200'){
                data.courseName=sessionStorage.getItem("courseName");
                let parNew = res.data.data.timeRound
                if (parNew == null) {return }
                data.alertParam.currentWeek = parNew.currentWeek
                data.alertParam.startTime = parNew.startTime.toString().slice(0,4) + '-'
                    + parNew.startTime.toString().slice(4,6)+'-'+parNew.startTime.toString().slice(6)
                data.alertParam.endTime = parNew.endTime.toString().slice(0,4) + '-'
                    + parNew.endTime.toString().slice(4,6)+'-'+parNew.endTime.toString().slice(6)
                   
              }
            })
          }
            return{
                ...toRefs(data),
              checkWeek
            }
        }
    })
</script>

<style lang="less" scoped>
/*.alertClass{*/
/*  position: fixed;*/
/*  width: 187px;*/
/*  right: 40px;*/
/*  top: 62px;*/
/*  z-index: 999;*/
/*}*/
/deep/.el-card__body{
  padding: 0 !important;
}
.week-div{
  margin: 22px 0 0 1px;
  text-align: center;
  float: left;
  width: 60px;
  height: 16px;
  font-size: 10px;
  padding: 2px;
  color: #3B6EFB;
  border: #3B6EFB 1px solid;
  border-radius: 15px;
}
.name-div{
  margin: 20px 8px 2px 19px;
  font-size: 20px;
  font-weight: bold;
  float: left;

}
.card-line {
  position: relative;
  top: 2px;
  display: inline-block;
  width: 6px;
  height: 20px;
  margin-right: 10px;
  background: #3B6EFB;
}
.course-time{
  display: inline-block;
  margin-top:10px;
  margin-left: 19px;

}
.margin-height{
  height: calc(100vh - 300px) !important;
  overflow: auto;
}
</style>
