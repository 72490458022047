export const listData = {
    drafrList : [
        {name:'课程ID',text: 'courseId'},
        {name:'课程名称',text: 'courseName'},
        {name:'小组名称',text: 'groupName'},
        {name:'基金经理',text: 'name'},
    ],
    currentPage:1,
    pageSize: 10,
    total: 0,
    classId: 0,
    loading: true,
    spreadTable:[],
    tableHeight: 400,
    operation: ['加入'],
    pageType: 1,
    lastState: 1000,

    userInput: undefined,

    showRoleDia: false,
    userItem:{},
    userValue:'',
    userOptions:[],
    itemList:[],
}