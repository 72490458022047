import {
  getTableList,
  findFundTrendByAcid,
  findIndexByAcidAndFid,
  forexOrders,
  findIndexByAcid,
  findAssetTrendByAcid,
} from "./groupApi";
import dayjs from "dayjs";
export const tagChanges = (item, data) => {
  data.tagType = item.key;
  if (item.name == '外汇') {
    foreignExchangeOrder(data)
  } else {
    getGroupTables(data);
  }
};
// 获取 table 数据
export const getGroupTables = (data) => {
  data.loading = true;
  let param = {
    acid: JSON.parse(sessionStorage.getItem("acid")),
    acId: JSON.parse(sessionStorage.getItem("acid")),
    // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
    startTime: data.startTime,
    endTime: data.endTime + 86399,
    type:  data.tagType,
    pageSize: data.pageSize,
    page: data.currentPage,
  };
  getTableList(param).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.spreadTable = dealList(newRes.portfolioManagements.list);
      data.total = newRes.portfolioManagements.total;
      setTimeout(() => {
        data.loading = false;
      }, 500);
    }
  });
};
// 格式化数据
export const dealList = (data) => {
  for (let i in data) {
    data[i].direction = data[i].direction === 0 ? "买入" : "卖出";
  }
  return data;
};
// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getGroupTables(data);
};
// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getGroupTables(data);
};
//  资产走势
export const trendCharts = (data) => {
  let date = [];
  let value = [];
  findFundTrendByAcid({
    acid: JSON.parse(sessionStorage.getItem("acid")),
    // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid
  }).then((res) => {
    if (res.data.code === "200") {
      let arr = res.data.data.fundTrendInformations;
      for (let i in arr) {
        date.push(arr[i].date);
        value.push(arr[i].netasset);
      }
      data.option = {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: date,
          boundaryGap: false,
        },
        yAxis: {
          type: "value",
        },
        series: [{
          data: value,
          type: "line",
          // smooth: true,
          areaStyle: {
             color:{
               type: 'linear',
               x: 0,
               y: 0,
               x2: 0,
               y2: 1,
               colorStops: [{
                   offset: 0, color: '#F7FCFF' // 0% 处的颜色
               }, {
                   offset: 1, color: '#F7FCFF' // 100% 处的颜色
               }],
               global: false // 缺省为 false
             },
                },
          lineStyle: {
            color: "#3B6EFB",
          },
          itemStyle: {
            color: "#3B6EFB",
          },
          symbol: "circle",
          symbolSize: 1
        }, ],
      };
    }
  });
};

//  指标
export const groupIndexs = (data) => {
  findIndexByAcidAndFid({
    acid: JSON.parse(sessionStorage.getItem("acid")),
    benchmark: "SZ50",
  }).then((res) => {
    if (res.data.code === "200") {
      let newRew = res.data.data;
      // 定义指标数组
      let indicatorInformation = newRew.indicatorInformation ? newRew.indicatorInformation : 0;
      let jensenMeasure = indicatorInformation.jensenMeasure ? indicatorInformation.jensenMeasure : 0;
      let sharpeRatio = indicatorInformation.sharpeRatio ? indicatorInformation.sharpeRatio : 0;
      let tenorRatio = indicatorInformation.tenorRatio ? indicatorInformation.tenorRatio : 0;
      let informationRatio = indicatorInformation.informationRatio ? indicatorInformation.informationRatio : 0;
      data.normItem = [
        sharpeRatio,
        jensenMeasure,
        tenorRatio,
        informationRatio,

      ];
    }
  });
};


// 查询所有外汇订单
export const foreignExchangeOrder = (data) => {
  let params = {
    acId: JSON.parse(sessionStorage.getItem('acid')),
    pageIndex: data.pageIndex,
    pageSize: data.pageSize
  }
  forexOrders(params).then((res) => {
    let newRes = res.data
    if (newRes.code == 200) {
      newRes.data.forexOrderVOList.forEach(item => {
        item.createTime = dayjs(item.createTime).format("YYYY-MM-DD HH:mm:ss")
        if (item.orderState == 1) {
          item.orderState = "委托中"
        } else if (item.orderState == 2) {
          item.orderState = "已取消"
        } else if (item.orderState == 3) {
          item.orderState = "交易完成"
        }
        item.sellerBalanceed = item.sellerCurrency + ':' + item.sellerBalance
      });
      data.spreadTable = newRes.data.forexOrderVOList
    }
  })
}

// 查询外汇指标
export const foreignExchangeIndicators = (data) => {

  let params = {
    acId: JSON.parse(sessionStorage.getItem('acid')),
    currencyId: data.activeName
  }
  findIndexByAcid(params).then(res => {
    let newRes = res.data
    if (newRes.code == 200) {
      data.tabForm = newRes.data.indicatorInformation
    }
  })
  data.loading = false
}

// 获取资产走势
export const obtainAssetTrend = (data) => {
  let params = {
    acId: JSON.parse(sessionStorage.getItem('acid')),
    currencyId: data.activeName
  }
  let date = [];
  let value = [];
  findAssetTrendByAcid(params).then(res => {
    let newRes = res.data
    if (newRes.code == 200) {
      let arr = newRes.data.assetTrendList
      for (let i in arr) {
        date.push(arr[i].date);
        value.push(arr[i].netAsset);
      }
      data.option = {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: date,
        },
        yAxis: {
          type: "value",
        },
        series: [{
          data: value,
          type: "line",
          smooth: true,
          lineStyle: {
            color: "#FEAE10",
          },
          itemStyle: {
            color: "#FEAE10",
          },
        }, ],
      };
    }
  })
}