<template>
  <el-header class="headerNav">
    <div class="container">
      <div class="logo">
        <img class="logoimg" :src="imgLogo" alt="logo图片" />
      </div>
      <div class="menusContainer">
        <!-- <listnav /> -->
      </div>
      <div class="avatarContainer">
      <img src="../../assets/teacher_icon.png" v-if="typeUser==='教师'"/>
      <img src="../../assets/manage_icon.png" v-else-if="typeUser==='超级管理员'" alt="">
      <img src="../../assets/student_icon.png" v-else="typeUser==='学生'" alt="">
     
      <!-- <el-avatar style="line-height: 80px"> </el-avatar> -->
<!--      <el-avatar size="large" :src="defaultimg"> </el-avatar>-->
<!--      <div class="profession">{{userData.profession}}</div>-->
        <!-- <el-avatar size="medium"> {{typeUser}} </el-avatar> -->
        <!--        <el-avatar size="medium" :src="avatarsrc"></el-avatar>-->
        <el-dropdown>
          <span class="el-dropdown-link">
            <span class="name">{{name}}({{ typeUser }})</span> <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-for="(item,index) in navlist" @click="navClick(item.name)" :key="index">{{item.name}}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

      </div>
    </div>
  </el-header>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import axios from '@/axios/globalaxios'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus';
import logo from "@/assets/Jaji-log.png"
import listnav from "../Headermenu/index"
import store from "@/store"

// import avatarimg from "@/assets/img.jpeg"
const navlist = [
  {
    name:'修改密码',
    link:'/personalSettings'
  },
  {
    name: '退出',
    link: ''
  },
]
export default {
  name: "globalheader",
  components: { listnav },
  setup() {
    const router = useRouter()
    const imgLogo = ref(logo);
    const typeUser = ref('');
    // const avatarsrc = ref(avatarimg)
    const name = ref('')
    onMounted(() => {
      name.value = JSON.parse(sessionStorage.getItem('user')).name
      typeUser.value = JSON.parse(sessionStorage.getItem('user')).userRoleName
    })
    const list = reactive(navlist)
    let navClick = (val) => {
      if (val === '退出') {
        let key = sessionStorage.getItem("codeKey");
        axios.post('/tradelab/user/logOut', {
          token: sessionStorage.getItem('token'),
          isServiceLogOut: key !== null ? true : false
        }).then(res => {
          if (res.data.code === '200') {
            sessionStorage.clear()
            sessionStorage.clear()
            store.commit('setroles',[])
            // sessionStorage.removeItem('token')
            // sessionStorage.removeItem('user')
            // sessionStorage.removeItem('token')
            // sessionStorage.removeItem('indexKey')
            ElMessage.success(res.data.msg)
            if (key !== null) {
              window.location.href = "https://login.sufe.edu.cn/cas/logout?service=https://login-test.sufe.edu.cn/cas/logout&ticket" + key;
              // window.location.href="https://login-test.sufe.edu.cn/cas/logout?service=https://login-test.sufe.edu.cn/cas/logout&ticket"+key;
            } else {
              router.push('/login')
            }
          }
        })
      } else if (val === '修改密码'){
        router.push('/personalSettings')
      }
    }
    return {
      imgLogo,
      typeUser,
      navlist,
      name,
      navClick,
    };
  },
};
</script>

<style lang="less" scoped>
.headerNav {
  width: 100%;
  background: #3B6EFB;
  color: #fff;
  padding: 0 50px;
  height: 58px;
  position: relative;
  .container {
    display: flex;
    align-items: center;
    height: 100%;
    .logo {
      margin-right: 20px;
      .logoimg {
        width: 130px;
        margin-left: -43px !important;
      }
    }
    .menusContainer {
      height: 100%;
    }
    .avatarContainer {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      img{
        width: 40px;
      }
      .el-dropdown-link {
        color: #fff;
        .name {
          display: inline-block;
          margin: 0 5px 0 10px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;

        }
      }
    }
  }
}
</style>
