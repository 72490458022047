<template>
    <div :class="{'border-Class':borderClass,'Nav-head':direction,'Nav-head-stand':!direction}" :style="{width:width}">
        <span class="Nav-item"  :class="navIndex == item.key?'tab-active':''" v-for="(item,index) in menuData.filter(menu => !menu.hide)" :key="index">
            <span v-if="!item.children||(item.router.includes('/course/trade'))||(item.router.includes('/megagame/trade'))||(item.router.includes('/course/report'))" @click="optionTab(item.key,item.router,item)" class="container" >
                <template v-if="routerType">
                  <el-button v-if="borderClass" size="mini" @click="clickTag(item)" class="container_text">{{item.name}}</el-button>
                  <span v-else size="mini" @click="clickTag(item)" class="container_text">{{item.name}}</span>
                </template>
                <template v-else>
<!--                    <router-link :to="item.router" @click="setKey(item.key)">{{item.name}}</router-link>-->
                    <span>{{item.name}}</span>
                </template>
            </span>
            <template v-else>
                <el-dropdown>
                  <span class="el-dropdown-link">{{item.name}}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item v-for="(list,index) in item.children.filter(child => !child.hide)" :key="index"
                                              :class="navSonIndex===list.key?'tab-dropdown-active':''"
                                              @click="optionTab(item.key,list.router,list.key)">
                                <template v-if="routerType"  ><span class="block" @click="clickTag(list)">{{list.name}}</span></template>
                                <template v-else>
                                    <router-link :to="list.router" style="color:#303133;" @click="setKey(list.key)">
                                      <span class="block">{{list.name}}</span>
                                    </router-link>
                                </template>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </template>
        </span>
    </div>
</template>

<script>
    import {defineComponent, reactive,ref, toRefs,onMounted,watch,} from 'vue'
    import {onBeforeRouteUpdate, useRouter,useRoute} from "vue-router";
    import {showTab,tagChage} from './navMethods'
    import {navData} from './navData'
    import {props} from './navProps'

    export default defineComponent({
        name: "index",
        props: props,
        setup(props, ctx) {
          let nowIndex = reactive(navData)
          let borderClass=ref(props.borderClass)
          let router = useRouter()
          let route = useRoute()
          let data = reactive({
            navIndex: 1,
            navSonIndex: -1,
          });

          onMounted(() => {
            lookRouter(props.menuData)
            
          })
          // 监听路有 menu绑定
          // let test = ()=>{data.navIndex = 1;}
            watch(() => [props.activeTableftkey,props.menuData],([newVal,newMenu]) => {
              if (newMenu){
                let arr = newMenu
                for (let i in arr){
              
                  if (arr[i].hasOwnProperty('router')){
                    if (route.path.indexOf(arr[i].router) !== -1){
                      data.navIndex = arr[i].key
                    }
                    let sonMenu = arr[i].children
                    for (let j in sonMenu){
                      if (sonMenu[j].router.indexOf(route.path) !== -1){
                        data.navSonIndexœ = sonMenu[j].key
                      }
                    }
                  }
                }
              }
              if (newVal){
                if(newVal.indexOf('-') > -1) {
                  const arr = newVal.split('-');
                  data.navIndex = arr[0];
                  data.navSonIndex = newVal
                }else{
                  data.navIndex = newVal
                }
              }
            })
          // onBeforeRouteUpdate((to) => {
          //   let arr = props.menuData
          //   for (let i in arr){
          //     if (arr[i].router === to.path){
          //       data.navIndex = arr[i].key
          //       return
          //     }
          //     for (let j in arr[i].children){
          //       if (arr[i].children[j].router === to.path){
          //         data.navSonIndex = arr[i].children[j].key
          //         return
          //       }
          //     }
          //   }
          // });


            let optionTab = (headIndexs,path,index = -1) =>{
              showTab(data,headIndexs,index)
              if(path) {
                if(path==='/course/trade'){
                  //交易管理
                  router.push(index.children[0].router)
                  sessionStorage.setItem('transactionManagement',JSON.stringify(index.children))
                } else if (path === '/megagame/trade') {
                  for (let i = 0; i < index.children.length; i++) {
                    if (!index.children[i].hide) {
                      router.push(index.children[i].router)
                      return
                    }
                  }
                }else if (path === '/course/report') {
                  router.push(index.children[0].router)
                }else{
                  router.push(path)
                }
               
              }
            }
          let lookRouter = (menuData) => {
              for (let i in menuData){
              if (route.path == menuData[i].router) {
                data.navIndex = menuData[i].key
              }
              if (menuData[i].children) {
                for (let j in menuData[i].children) {
                  if (route.path == menuData[i].children[j].router) {
                    data.navIndex = menuData[i].key
                  }
                }
              }
            }
            }
            let clickTag = (item) =>{tagChage(item,ctx)}
            let setKey = (item) =>{data.navIndex = item;sessionStorage.setItem('item',item)}
            return {
                ...toRefs(nowIndex),
              ...toRefs(data),
              ...toRefs(borderClass),
                lookRouter,
                optionTab,
                clickTag,
                setKey,
                
            }
        }
    })
</script>

<style scoped lang="less">
    .border-Class{
      border-bottom:none !important;
      .Nav-item{
        padding:0 5px!important;
      }
      .tab-active {
        border-bottom: 0px solid #fff !important;
      }

    }
    .Nav-head {
      background-color: #F4F4FA;
        border-bottom: 1px solid #e4e7ed;
        margin-bottom: 20px;
        .Nav-item {
            display: inline-block;
             padding: 0 20px;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            font-weight: 500;
            color: #303133;
            .container{
                display: block;
                .container_text{
                    display: block;
                    padding: 0 20px;
                    font-size: 14px;
                }
            }
            a{
                font-size: 14px;
                font-weight: 500;
                color: #303133;
            }

            &:hover a{
                color: #4274FC;
                cursor: pointer;
            }
            &:hover{
                color: #4274FC;
                cursor: pointer;
            }
        }

        .tab-active {
            a{
                color: #4274FC;
            }
            color: #4274FC;
            font-weight: 700;
            margin-bottom: -1px;
            border-bottom: 2px solid #4274fc;
            // border-left: 1px solid #dcdfe6;
            // border-right: 1px solid #dcdfe6;
            // border-top: 2px solid #faad14;
        }
    }

    .Nav-head-stand {
      background-color: #FFF;
        border-bottom: 1px solid #e4e7ed;
        margin-bottom: 20px;
        .Nav-item {
            display: inline-block;
             padding: 0;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            font-weight: 500;
            color: #303133;
            .container{
                display: block;
                .container_text{
                    display: block;
                    padding: 0 20px;
                    font-size: 14px;
                }
            }
            a{
                font-size: 14px;
                font-weight: 500;
                color: #303133;
            }

            &:hover a{
                color: #4274FC;
                cursor: pointer;
            }
            &:hover{
                color: #4274FC;
                cursor: pointer;
            }
        }

        .tab-active {
            a{
                color: #4274FC;
            }
            color: #4274FC;
            font-weight: 700;
            margin-bottom: -1px;
            border-bottom: 2px solid #4274FC;
            // border-left: 1px solid #dcdfe6;
            // border-right: 1px solid #dcdfe6;
            // border-top: 2px solid #faad14;
        }
    }
    // .Nav-head-stand {
    //   background-color: #FFFF;
    //   border-bottom: 1px solid #e4e7ed;
    //     height: 80vh;
    //     display: inline;
    //     .Nav-item {
            
    //         font-size: 12px;
    //         font-weight: 500;
    //         text-align: center;
    //         color: #303133;
    //         .container{
    //             display: inline;
    //             .container_text{
    //                 display: inline-block;
    //                 padding: 15px 20px;
    //                 font-size: 14px;
    //             }
    //         }
    //         &:deep(.el-dropdown) {
    //             display: block;
    //             padding: 15px 20px;
    //         }


    //         a{
    //             font-size: 14px;
    //             font-weight: 500;
    //             color: #303133;
    //         }

    //         &:hover a{
    //             color: #4274FC;
    //             cursor: pointer;
    //         }
    //         &:hover{
    //             color: #4274FC;
    //             cursor: pointer;
    //         }
    //     }

    //     .tab-active {
    //         a{
    //             color: #4274FC;
    //         }
    //         // margin-right: -1px;
    //         // margin-left: -20px;
    //         color: #4274FC;
    //         font-weight: 700;
    //         border-bottom: 2px solid #4274FC;
    //         box-sizing: content-box;
    //         // border-top: 1px solid #dcdfe6;
    //         // border-bottom: 1px solid #dcdfe6;
    //         // border-left: 2px solid #faad14;
    //     }
    // }
    .el-dropdown-menu__item{
        padding: 0 0;
    }
    .tab-dropdown-active{
        background: #5a9cf84f;
        color: black;
    }
    .block{
        display: block;
        padding: 0 20px;
    }
</style>