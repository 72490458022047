export const props = {
    routerType: { // 默认为页面级菜单， false 为路由转跳
        type: Boolean,
        default: true
    },
    direction: { // 默认为横排， false 为竖排
        type: Boolean,
        default: true
    },
    menuData: {
        type: Array,
        default: []
    },
    width:{
        type:[Number,String]
    },
    activeTableftkey:{
        type:String
    },
    borderClass :{ 
        type: Boolean,
        default: false
    },
}