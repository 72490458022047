import router from "./router"
import store from "./store"
import { ElMessage } from "element-plus"
import { findResourceByRoleId } from "@/views/system-manage/role-manage/dataApi.js"
import { getMenu } from "@/api/menu.js"
import { concat } from "lodash"
const whiteList = ["/login", "/login/Single", "/register"]

router.beforeEach((to, from, next) => {
  let getToken = sessionStorage.getItem("token");
  if (getToken) {
      let roleList = JSON.parse(sessionStorage.getItem("roleList"))
      if (store.state.roles.length === 0 ) {
        if(JSON.parse(sessionStorage.getItem("user"))?.userRoleId){
          store.commit("setroles", [
            JSON.parse(sessionStorage.getItem("user")).userRoleId
          ])
          if(roleList?.length>0){
          let accessRoutes = getMenu(roleList)
            accessRoutes.forEach((route) => {
              router.addRoute(route) // 动态添加可访问路由表
            })
          }
       
          //添加404页面
          router.addRoute({
                path: '/:pathMatch(.*)*',
                name: 'notFound',
                component: () =>  import ( "@/views/notFound")
            },) 
          next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
        }else{
          next()
        }
   
      } else {
        next()
      }
    // }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      
      next()
    } else {
      next(`/login`) // 否则全部重定向到登录页
    }
  }
})
