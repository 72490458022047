<template>
  <div>
    <!-- 新写的表格传userType以区分，防止与原来表格信息错乱，未传值默认为0 -->
    <el-table :data="tableData" header-align="center" :min-height="tableHeight + 'px'" :ref="table" :tree-props="{ children: 'sysUserList', hasChildren: 'hasChildren' }" :highlight-current-row="highlight" id="targetDom" @selection-change="handleSelectionChange" @current-change="highlightChange" :header-cell-style="{ background: '#F8F8F8', color: '#03032C' }" style="width: 100%">
      <el-table-column v-if="selection" type="selection" width="55">
      </el-table-column>
      <el-table-column v-if="singelSelect" width="55" prop="singelSelect">
        <template #default="scope">
          <el-radio @change="highlightChange" v-model="singelSelectRadio" :label="scope.row.singelSelect">{{''}}</el-radio>
        </template>
      </el-table-column>
      <el-table-column v-if="indexMode" :label="indexTitle" type="index" width="80">
      </el-table-column>

      <template v-for="(item, index) in tableColumn">
        <el-table-column align="center" :key="index" v-if="!item.hidden" :show-overflow-tooltip="true" :prop="item.text" :label="item.name">
          <template #default="scope">
            <template v-if="item.text === 'tiedStockList'">
              <span v-for="(list, subIndex) in scope.row[item.text]" :key="subIndex" style="font-size: 12px">{{
                  list.sharesName
              }}</span>
            </template>
            <template v-else-if="item.text === 'whetherState'">
              <el-tag effect="light" :type="scope.row[item.text] === '延迟' ? 'danger' : ''">
                {{ scope.row[item.text] }}</el-tag>
            </template>
            <template v-else-if="item.name === '111'">
              <span>{{ item.text }}</span>
            </template>
            <template v-else-if="item.text === 'views'">
              <span :style="{color: item.name == '浏览次数'?'#2488FB':''}">{{ scope.row[item.text] }}</span>
            </template>
            <template v-else>
              <span>{{ scope.row[item.text] }}</span>
            </template>
          </template>
        </el-table-column>
      </template>
      <template v-if="beforeRouteName == 'schoolManage'">
        <el-table-column label="注册开关">
          <template #default="scope">
            <el-switch v-model="scope.row.registeredSwitch" size="large" @change="SwitchButton(scope.row)" />
          </template>
        </el-table-column>
      </template>

      <!-- 操作栏 -->
      <el-table-column v-if="operation.length !== 0" label="操作" width="160">
        <template #default="scope">
          <!-- <template v-if="item.type === '删除'">
                <el-popover
                  placement="top"
                  :width="160"
                  v-model:visible="visible"
                  trigger="click"
                >
                  <p>是否确认关闭此大赛？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="clickButton(item,scope.row)">确定</el-button>
                  </div>
                  <template #reference>
                    <el-button type="text" size="small" @click="visible = true"
                              v-for="(item,index) in operation" :key="index">
                      <template v-if="upreport">
                        <span v-if="scope.row.fileState===0">{{item}}</span>
                        <span v-if="scope.row.fileState!==0&&['查看','对应目标'].indexOf(item) !== -1">{{item}}</span>
                      </template>
                      <template v-else>
                        <span v-if="scope.row.goodType ===0 || ['评为优秀'].indexOf(item) === -1">{{item}}</span>
                      </template>
                    </el-button>
                  </template>
                </el-popover>
              </template>
              <template v-else> -->
          <el-button type="text" :class="{'delete-text':item==='删除'}" size="small" @click="clickButton(item, scope.row)" :disabled="(scope.row.orderStatusName && ['部撤完成', '已撤', '已成', '废单'].indexOf(scope.row.orderStatusName) !== -1) || (scope.row.isJoinGroup === false && operation[0] == '加入')" v-for="(item, index) in operation" :key="index">
            <!-- 原来已有的 -->
            <template v-if="beforeRouteName === '0'">
              <template v-if="upreport">
                <span v-if="scope.row.fileState === 0">{{ item }}</span>
                <span v-if="scope.row.fileState !== 0 && ['查看', '对应目标'].indexOf(item) !== -1">{{ item }}</span>
              </template>
              <template v-else>
                <!-- <span v-if="scope.row.goodType ===0 || ['查看','拉入' ,'编辑' ,'删除','修改余额','成员权限'].indexOf(item) !== -1">{{item}}</span>-->
                <span v-if="scope.row.goodType === 0 || ['评为优秀'].indexOf(item) === -1" :style="{color:item == '评为优秀'||item == '删除'?'#E75A4B':''}">{{ item }}</span>
              </template>
            </template>
            <!-- 新写的表格操作栏判断建议写在这里，传beforeRouteName以区分，防止错乱，未传值默认为0 -->
            <template v-else>
              <template v-if="beforeRouteName == 'courseStock' || beforeRouteName == 'courseControl'">
                <template v-if="upreport">
                  <template v-if="disabledTeacher">
                    <span v-if="disabledTeacher  && ['查看', '对应目标'].indexOf(item) !== -1">{{ item }}</span>
                  </template>
                  <template v-else>
                    <span v-if="scope.row.fileState === 0">{{ item }}</span>
                    <span v-if="scope.row.fileState !== 0 && ['查看', '对应目标'].indexOf(item) !== -1">{{ item }}</span>
                  </template>
                </template>
              </template>
              <template v-if="(item == '设置角色' || item == '删除' || item == '编辑' || item == '重置') && (beforeRouteName == 'userManage' || beforeRouteName == 'roleManage')">
                <span v-if="scope.row.roleName == '超级管理员'">{{ item == '编辑' ? item : '' }}</span>
                <span v-else>{{ item }}</span>
              </template>
              <template v-if="beforeRouteName == 'schoolManage'">
                <span>{{ item }}</span>
              </template>
              <template v-if="beforeRouteName == 'informationStudent'">
                <template v-if="userRoleName !== '学生'">
                  <span>{{ item }}</span>
                </template>
                <template v-else>
                  <span v-if="item == '删除'"></span>
                  <span v-else>{{ item }}</span>
                </template>
              </template>
              <template v-if="beforeRouteName == 'UpreportDraft'">
                <span v-if="showStudent  && item!='查看'">{{ item }}</span>
                <span v-if="!showStudent  && item =='查看'">{{ item }}</span>
              </template>
            </template>
          </el-button>
        </template>
        <!-- </template> -->
      </el-table-column>
    </el-table>
    <template v-if="currentPage !== 0 && currentPageShow != 1 && total !== 0">
      <el-pagination class="pagination-table" v-if="smallType" @size-change="handleSizeChange" @current-change="handleCurrentChange" small layout="prev, pager, next" :current-page.sync="currentPage" :page-size.sync="pageSize" :total="total">
      </el-pagination>
      <el-pagination v-else class="pagination-table" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize" layout=" prev, pager, next, sizes,jumper" :total="total">
      </el-pagination>
    </template>
    <!-- </template> -->
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, nextTick, onMounted } from 'vue'
import { handleSizeChanges, handleCurrentChanges, clickButtons, handleSelectionChanges, toggleSelections, controls, SwitchButtons } from './tableMethods'
import { props } from './tableProps'
export default defineComponent({
  name: "index",
  props: props,
  setup(props, ctx) {
    let refs = '';
    const table = el => {
      refs = el;
    }
    let methods = {
      handleScroll() {

        if (props.tableDetail.noMore || props.tableDetail.loadingMore) return;
        let dom = refs.layout.table.refs.bodyWrapper;
        let scrollTop = dom.scrollTop
        // 变量windowHeight是可视区的高度
        let windowHeight = dom.clientHeight || dom.clientHeight
        // 变量scrollHeight是滚动条的总高度
        let scrollHeight = dom.scrollHeight || dom.scrollHeight
        if (scrollTop + windowHeight >= scrollHeight - 20) {
          // 获取到的不是全部数据 当滚动到底部 继续获取新的数据
          if (props.tableDetail.isTotal) {
            ctx.emit('scorllChange', 'noMore')
            return
          }
          ctx.emit('scorllChange', 'loadingMore')
        }
      },
    };
    onMounted(() => {
      if (props.addEvenTable) {
        let tables = refs.layout.table.refs.bodyWrapper;
        tables.addEventListener(
          "scroll",
          () => {
            methods.handleScroll();
          },
          true
        );
      }
    })
    let data = reactive({
      userRoleName: JSON.parse(sessionStorage.getItem('user')).userRoleName
      // value : ref(true),
    })
    let handleSizeChange = (val) => { handleSizeChanges(val, ctx) }
    let handleCurrentChange = (val) => { handleCurrentChanges(val, ctx) }
    let clickButton = (val, item) => {
      console.log("val-11",item);
      clickButtons(val, item, ctx)
    }
    let handleSelectionChange = (val) => { handleSelectionChanges(val, ctx) }
    let toggleSelection = () => { toggleSelections(refs) }
    let control = (val) => { controls(val, ctx) }
    let SwitchButton = (val) => { SwitchButtons(val, ctx) }
    const highlightChange = (val) => {
      ctx.emit('highlightChange', val)
    }
    return {
      ...toRefs(data),
      handleSizeChange,
      handleCurrentChange,
      clickButton,
      table,
      handleSelectionChange,
      toggleSelection,
      highlightChange,
      SwitchButton,
      control,
      methods,
      visible: ref(false)
    }
  }
})
</script>

<style scoped lang="less">
.pagination-table {
  text-align: right;
  margin-top: 10px;
}

/*滚动条的宽度*/
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/*滚动条滑块*/
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #ddd;
}
.delete-text{
  color:#f56c6c;
}
/deep/.el-tag{
  background-color: rgba(255,255,255,0);
  border:none;

} 
</style>
