import axios from '@/axios/globalaxios'

export const findQuotationByCondition = data =>{
    return axios({
        url: "/tradelab/marketPortfolioManagement/findQuotationByCondition",
        method: 'post',
        data:data
    })
}
export const findCashFlow = data =>{
    return axios({
        url: "/tradelab/transaction/findCashFlow",
        method: 'post',
        data:data
    })
}
export const orderTransactionInformation = data =>{
    return axios({
        url: "/tradelab/transaction/findTransactionInformation",
        method: 'post',
        data:data
    })
}
// 撤单
export const cancelOrder = data =>{
    return axios({
        url: "/tradelab/transaction/cancelOrder",
        method: 'post',
        data:data
    })
}

// 委托
export const findDelegation = data =>{
    return axios({
        url: "/tradelab/transaction/findDelegation",
        method: 'post',
        data:data
    })
}

// 资金账户
export const findPortfolioManagementDetailedByAcidAndType = data =>{
    return axios({
        url: "/tradelab/marketPortfolioManagement/findPortfolioManagementDetailedByAcidAndType",
        method: 'post',
        data:data
    })
}
// 保证金
export const findSecurityDeposit = data =>{
    return axios({
        url: "/tradelab/marketPortfolioManagement/findSecurityDeposit",
        method: 'post',
        data:data
    })
}
// 分红送股
export const accountHistoryStockDividendInformation = data =>{
    return axios({
        url: "/tradelab/Management/accountHistoryStockDividendInformation",
        method: 'post',
        data:data
    })
}
